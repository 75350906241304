const autoData = {
  Acura: {
    "MDX 4WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    TSX: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "RDX 4WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    ILX: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    RL: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "RDX 2WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    RLX: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "TLX AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
    },
    "TL 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "RLX Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
    },
    NSX: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "TL 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "3.5RL": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "RDX FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    RSX: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "RDX AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    TL: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "TLX FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "3.2TL": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "ZDX 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "MDX Hybrid AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "MDX FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "MDX AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "NSX Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "TLX AWD A-SPEC": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "TLX FWD A-SPEC": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "TSX Wagon": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "RDX FWD A-SPEC": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "RDX AWD A-SPEC": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "3.2CL": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "MDX 2WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    MDX: {
      2002: "2002",
      2001: "2001",
    },
    Integra: {
      2001: "2001",
      2000: "2000",
    },
    "ILX Hybrid": {
      2014: "2014",
      2013: "2013",
    },
    "MDX AWD A-SPEC": {
      2020: "2020",
      2019: "2019",
    },
    "TLX 2WD": {
      2016: "2016",
    },
    "TLX 4WD": {
      2016: "2016",
    },
    TLX: {
      2015: "2015",
    },
  },
  "Alfa Romeo": {
    "4C": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Giulia: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Giulia AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Stelvio AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Stelvio: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "8 C Spider": {
      2009: "2009",
    },
  },
  "Aston Martin": {
    "V8 Vantage": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    DB9: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2005: "2005",
    },
    "V8 Vantage S": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Rapide S": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    DBS: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Vanquish: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2003: "2003",
      2002: "2002",
    },
    "DB11 V12": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "DB11 V8": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "DB9 Volante": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "DB9 Coupe Manual": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    Rapide: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "DB9 Volante Manual": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "DB9 Coupe": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "V12 Vantage S": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Vantage V8": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Vanquish S Zagato": {
      2018: "2018",
      2017: "2017",
    },
    "V12 Vanquish S": {
      2006: "2006",
      2005: "2005",
    },
    "DB-7 Vantage Volante": {
      2003: "2003",
      2000: "2000",
    },
    "DB-7 Vantage Coupe": {
      2003: "2003",
      2000: "2000",
    },
    "V12 Vantage": {
      2012: "2012",
      2011: "2011",
    },
    "Vantage GT": {
      2016: "2016",
      2015: "2015",
    },
    "Vantage Manual": {
      2021: "2021",
      2020: "2020",
    },
    "DB-7 GT Coupe": {
      2003: "2003",
    },
    "DB-7 GT Volante": {
      2003: "2003",
    },
    "DB AR1": {
      2003: "2003",
    },
    "V12 Vanquish": {
      2004: "2004",
    },
    "V8 Vantage ASM": {
      2007: "2007",
    },
    "DBS Coupe": {
      2009: "2009",
    },
    Virage: {
      2012: "2012",
    },
    "Vanquish S": {
      2019: "2019",
    },
    "DBX V8": {
      2021: "2021",
    },
  },
  Audi: {
    "A6 quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "A4 quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "A8 L": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "TT Coupe quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    A4: {
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "TT Roadster quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    S4: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    A6: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "A3 quattro": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    S5: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    Q7: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    A3: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "A5 quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "A4 Avant quattro": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    S6: {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Q5: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "S5 Cabriolet": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "A5 Cabriolet quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    A8: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2003: "2003",
    },
    S8: {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2003: "2003",
    },
    "A6 Avant quattro": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "A7 quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "TT Coupe": {
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    R8: {
      2021: "2021",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    SQ5: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "TT Roadster": {
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    S7: {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "A4 Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Q3 quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "S4 Avant": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2002: "2002",
      2001: "2001",
    },
    "RS 5": {
      2021: "2021",
      2019: "2019",
      2018: "2018",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    S3: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "S4 Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "A3 Cabriolet quattro": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "R8 Spyder": {
      2021: "2021",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2012: "2012",
      2011: "2011",
    },
    "RS 7": {
      2021: "2021",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "A4 Cabriolet quattro": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "TTS Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "allroad quattro": {
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "A5 Cabriolet": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "A3 Cabriolet": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Q5 Hybrid": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "S5 Sportback": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Q3: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "A4 allroad quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2017: "2017",
    },
    "TT RS": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "A5 Sportback quattro": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RS 5 Cabriolet": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "A8 quattro": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "A3 e-tron": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "RS 3": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Allroad quattro": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Q8: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    Allroad: {
      2002: "2002",
      2001: "2001",
    },
    "A6 Allroad": {
      2021: "2021",
      2020: "2020",
    },
    SQ8: {
      2021: "2021",
      2020: "2020",
    },
    "R8 AWD": {
      2020: "2020",
      2018: "2018",
    },
    RS4: {
      2008: "2008",
      2007: "2007",
    },
    "S8 quattro": {
      2002: "2002",
      2001: "2001",
    },
    SQ7: {
      2021: "2021",
      2020: "2020",
    },
    "R8 Spyder AWD": {
      2020: "2020",
      2018: "2018",
    },
    "TT RS Coupe": {
      2013: "2013",
      2012: "2012",
    },
    "S6 Avant": {
      2003: "2003",
      2002: "2002",
    },
    "RS Q8": {
      2021: "2021",
      2020: "2020",
    },
    "A4 Ultra": {
      2018: "2018",
      2017: "2017",
    },
    "RS 5 Sportback": {
      2021: "2021",
      2019: "2019",
    },
    "RS4 Cabriolet": {
      2008: "2008",
    },
    "A3 e-tron ultra": {
      2016: "2016",
    },
    "e-tron": {
      2019: "2019",
    },
    "A4 Avant": {
      2000: "2000",
    },
    "A5 Sportback S line quattro": {
      2021: "2021",
    },
    RS3: {
      2020: "2020",
    },
    "R8 RWD": {
      2018: "2018",
    },
    "e-tron Sportback": {
      2020: "2020",
    },
    RS6: {
      2003: "2003",
    },
    "Q3 S-line quattro": {
      2021: "2021",
    },
    "R8 2WD": {
      2021: "2021",
    },
    "R8 Spyder RWD": {
      2018: "2018",
    },
    "A4 S line quattro": {
      2021: "2021",
    },
    "RS 6 Avant": {
      2021: "2021",
    },
    "R8 Spyder 2WD": {
      2021: "2021",
    },
  },
  "Azure Dynamics": {
    "Transit Connect Electric Van": {
      2012: "2012",
    },
    "Transit Connect Electric Wagon": {
      2012: "2012",
    },
  },
  Bentley: {
    "Continental GT": {
      2021: "2021",
      2020: "2020",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Arnage: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Mulsanne: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Continental Flying Spur": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Continental GTC": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Azure: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2003: "2003",
      2001: "2001",
      2000: "2000",
    },
    "Flying Spur": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Continental GT Convertible": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Arnage LWB": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Continental Supersports Convertible": {
      2018: "2018",
      2017: "2017",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Bentayga: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Continental Supersports": {
      2017: "2017",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Continental R": {
      2003: "2003",
      2001: "2001",
      2000: "2000",
    },
    "Continental T": {
      2003: "2003",
      2001: "2001",
      2000: "2000",
    },
    "Continental SC": {
      2001: "2001",
      2000: "2000",
    },
    "Arnage RL": {
      2009: "2009",
      2008: "2008",
    },
    Brooklands: {
      2010: "2010",
      2009: "2009",
    },
    "Continental GT Speed Convertible": {
      2014: "2014",
    },
    "Continental GT3-R": {
      2015: "2015",
    },
    "Mulsanne EWB": {
      2017: "2017",
    },
  },
  BMW: {
    M5: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "750i": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    M3: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "X6 xDrive50i": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "X6 xDrive35i": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "750i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "740i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2001: "2001",
    },
    "530i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "M3 Convertible": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "550i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "328i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2000: "2000",
    },
    "M6 Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "330i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "528i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2000: "2000",
    },
    "X5 xDrive50i": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "535i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "335i": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "540i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "X6 M": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X1 xDrive28i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X1 sDrive28i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X5 xDrive35d": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "X5 xDrive35i": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Z4 sDrive35i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "535i xDrive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "535i Gran Turismo": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "650i Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    X5: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "650i xDrive Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "535i xDrive Gran Turismo": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "525i": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "640i Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X3 xDrive28i": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "328i xDrive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "X3 xDrive35i": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "M6 Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "650i Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "528i xDrive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
    },
    "640i Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "X5 M": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
    },
    "325xi Sport Wagon": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "328ci": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2000: "2000",
    },
    "325ci": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "325i": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "328ci Convertible": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "750Li xDrive": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "320i xDrive": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Z4 sDrive35is": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "640i xDrive Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "128i": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "135i Convertible": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "550i xDrive Gran Turismo": {
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "M4 Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "320i": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X4 M40i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "325xi": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "335i xDrive": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "640i Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "335ci Convertible": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Z4 sDrive30i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "330xi": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "550i xDrive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "750Li": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "X3 xDrive30i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2010: "2010",
      2009: "2009",
    },
    "760Li": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "650i Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "135i": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "M4 Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "M3 Coupe": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "325ci Convertible": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "330ci": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "760li": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "330ci Convertible": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "M240i Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "M240i Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "740Li": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "740i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "335ci": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "M760i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "M240i xDrive Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "430i Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "640i xDrive Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "530i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "230i xDrive Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "328d xDrive": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "650i xDrive Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Alpina B7 SWB": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "650ci Convertible": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "230i Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "M240i xDrive Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "430i xDrive Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Alpina B7 LWB": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Z4 sDrive28i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Alpina B7 SWB xDrive": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Alpina B7 LWB xDrive": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "328d": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "X5 sDrive35i": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "X6 sDrive35i": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "M6 Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "550i Gran Turismo": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    M6: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "328i Sport Wagon": {
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "650ci": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "330i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "230i Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "328d xDrive Sports Wagon": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "540i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "325i Sport Wagon": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "ActiveHybrid 5": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "745i": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    i8: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "440i Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "X2 xDrive28i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Z8: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "440i xDrive Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "750li": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "745li": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "X4 xDrive28i": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "X2 sDrive28i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "128ci Convertible": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "M550i xDrive": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "430i Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "328i xDrive Sports Wagon": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2011: "2011",
    },
    "430i xDrive Gran Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "540i Sport Wagon": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "ActiveHybrid 7L": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "430i xDrive Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "440i xDrive Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "430i Gran Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "X3 M40i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "440i Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "230i xDrive Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "650i xDrive Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "640i xDrive Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "440i Gran Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "330e": {
      2021: "2021",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "440i xDrive Gran Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "340i xDrive Gran Turismo": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Z4 3.0si": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "335is Coupe": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Alpina B6 xDrive Gran Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "i3 REX": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "X6 xDriveM": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "428i Convertible": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "335ci xDrive": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "i3 BEV": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Z3 Coupe": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "228i": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "328i xDrive Gran Turismo": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "740Li xDrive": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "X7 xDrive40i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "X5 xDrive40i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Z4 M Coupe": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "760i": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "ActiveHybrid 3": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "525i Sport Wagon": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "428i xDrive Convertible": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "X5 xDriveM": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "530e": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Alpina B7 xDrive": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "M850i xDrive Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "340i xDrive": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "428i xDrive Coupe": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "535d": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Z4 Roadster": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "435i Coupe": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "428i Coupe": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "530e xDrive": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "M235i xDrive": {
      2020: "2020",
      2016: "2016",
      2015: "2015",
    },
    "X2 M35i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "X4 xDrive30i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "435i Convertible": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    X3: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "330i xDrive Sports Wagon": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "X5 xDrive40e": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "435i xDrive Coupe": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    M235i: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    M2: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Z3 Roadster": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "335i xDrive Gran Turismo": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "335is Convertible": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "330i xDrive Gran Turismo": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "X3 sDrive30i": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "740e xDrive": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "M5 Competition": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "535d xDrive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "328ci xDrive": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "X1 xDrive35i": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Z4 M Roadster": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "335d": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "340i": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Z4 3.0i": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "M850i xDrive Coupe": {
      2020: "2020",
      2019: "2019",
    },
    "X7 xDrive50i": {
      2020: "2020",
      2019: "2019",
    },
    "I8 Coupe": {
      2020: "2020",
      2019: "2019",
    },
    "X6 xDrive40i": {
      2021: "2021",
      2020: "2020",
    },
    "328i Sport Wagon xDrive": {
      2010: "2010",
      2009: "2009",
    },
    "535i Sport Wagon xDrive": {
      2010: "2010",
      2009: "2009",
    },
    "428i xDrive Gran Coupe": {
      2016: "2016",
      2015: "2015",
    },
    "328cxi": {
      2008: "2008",
      2007: "2007",
    },
    "228i xDrive Convertible": {
      2016: "2016",
      2015: "2015",
    },
    "Alpina B6 Gran Coupe xDrive": {
      2016: "2016",
      2015: "2015",
    },
    "M4 CS": {
      2020: "2020",
      2019: "2019",
    },
    "328i Coupe": {
      2013: "2013",
      2012: "2012",
    },
    "X3 3.0si": {
      2008: "2008",
      2007: "2007",
    },
    "M Coupe": {
      2001: "2001",
      2000: "2000",
    },
    "X5 4.8is": {
      2006: "2006",
      2005: "2005",
    },
    "M4 Convertible Competition": {
      2020: "2020",
      2019: "2019",
    },
    "X3 xDrive28d": {
      2016: "2016",
      2015: "2015",
    },
    "640i xDrive Gran Turismo": {
      2019: "2019",
      2018: "2018",
    },
    "Z4 Coupe": {
      2008: "2008",
      2007: "2007",
    },
    "228i Convertible": {
      2016: "2016",
      2015: "2015",
    },
    "650i Convertible xDrive": {
      2013: "2013",
      2012: "2012",
    },
    "X6 sDrive40i": {
      2021: "2021",
      2020: "2020",
    },
    "745e xDrive": {
      2021: "2021",
      2020: "2020",
    },
    "645ci": {
      2005: "2005",
      2004: "2004",
    },
    "X3 M": {
      2021: "2021",
      2020: "2020",
    },
    "840i xDrive Convertible": {
      2021: "2021",
      2020: "2020",
    },
    "435i xDrive Gran Coupe": {
      2016: "2016",
      2015: "2015",
    },
    "X5 4.6is": {
      2003: "2003",
      2002: "2002",
    },
    "840i xDrive Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "428i Gran Coupe": {
      2016: "2016",
      2015: "2015",
    },
    "840i Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "X5 sDrive40i": {
      2021: "2021",
      2020: "2020",
    },
    "M2 Competition Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "M8 Competition Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    i3: {
      2020: "2020",
      2019: "2019",
    },
    "335i Coupe xDrive": {
      2013: "2013",
      2012: "2012",
    },
    "Alpina B7": {
      2021: "2021",
      2020: "2020",
    },
    "X4 xDrive35i": {
      2016: "2016",
      2015: "2015",
    },
    "X4 M": {
      2021: "2021",
      2020: "2020",
    },
    "ActiveHybrid X6": {
      2011: "2011",
      2010: "2010",
    },
    "545i": {
      2005: "2005",
      2004: "2004",
    },
    M340i: {
      2021: "2021",
      2020: "2020",
    },
    "i3s with Range Extender": {
      2020: "2020",
      2019: "2019",
    },
    "M Roadster": {
      2001: "2001",
      2000: "2000",
    },
    "328xi": {
      2008: "2008",
      2007: "2007",
    },
    "X5 M Competition": {
      2021: "2021",
      2020: "2020",
    },
    "X6 M Competition": {
      2021: "2021",
      2020: "2020",
    },
    i3s: {
      2020: "2020",
      2019: "2019",
    },
    "435i Gran Coupe": {
      2016: "2016",
      2015: "2015",
    },
    "M8 Competition Convertible": {
      2021: "2021",
      2020: "2020",
    },
    "435i xDrive Convertible": {
      2016: "2016",
      2015: "2015",
    },
    "I8 Roadster": {
      2020: "2020",
      2019: "2019",
    },
    "M240i xDrive Coupe M Performance": {
      2020: "2020",
      2019: "2019",
    },
    "i3 with Range Extender": {
      2020: "2020",
      2019: "2019",
    },
    "X5 4.8i": {
      2008: "2008",
      2007: "2007",
    },
    "X3 M Competition": {
      2021: "2021",
      2020: "2020",
    },
    "525xi": {
      2007: "2007",
      2006: "2006",
    },
    "840i xDrive Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "M240i Coupe M Performance": {
      2020: "2020",
      2019: "2019",
    },
    "X7 M50i": {
      2021: "2021",
      2020: "2020",
    },
    "X5 M50i": {
      2021: "2021",
      2020: "2020",
    },
    "X5 xDrive48i": {
      2010: "2010",
      2009: "2009",
    },
    "645ci Convertible": {
      2005: "2005",
      2004: "2004",
    },
    "335i Coupe": {
      2013: "2013",
      2012: "2012",
    },
    "M4 Coupe Competition": {
      2020: "2020",
      2019: "2019",
    },
    "228i xDrive Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "X5 3.0si": {
      2008: "2008",
      2007: "2007",
    },
    "M235i Convertible": {
      2016: "2016",
      2015: "2015",
    },
    "128i Convertible": {
      2009: "2009",
      2008: "2008",
    },
    "328xi Sport Wagon": {
      2008: "2008",
      2007: "2007",
    },
    "M8 Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "328i Coupe xDrive": {
      2013: "2013",
      2012: "2012",
    },
    "X3 sDrive28i": {
      2017: "2017",
      2015: "2015",
    },
    "840i Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "Z4 M40i": {
      2021: "2021",
      2020: "2020",
    },
    "228i xDrive": {
      2016: "2016",
      2015: "2015",
    },
    "X5 xDrive 35d": {
      2018: "2018",
      2017: "2017",
    },
    "650i Coupe xDrive": {
      2013: "2013",
      2012: "2012",
    },
    "530xi": {
      2007: "2007",
      2006: "2006",
    },
    "X5 xDrive30i": {
      2010: "2010",
      2009: "2009",
    },
    "530xi Sport Wagon": {
      2007: "2007",
      2006: "2006",
    },
    "M340i xDrive": {
      2021: "2021",
      2020: "2020",
    },
    "335xi": {
      2008: "2008",
      2007: "2007",
    },
    "X4 M Competition": {
      2021: "2021",
      2020: "2020",
    },
    "X6 M50i": {
      2021: "2021",
      2020: "2020",
    },
    "840i Convertible": {
      2021: "2021",
      2020: "2020",
    },
    "M4 GTS": {
      2016: "2016",
    },
    "535xi": {
      2008: "2008",
    },
    "X3 xDrive30e": {
      2020: "2020",
    },
    "540d xDrive": {
      2018: "2018",
    },
    "Active E": {
      2011: "2011",
    },
    "550 GT": {
      2010: "2010",
    },
    "550i xDrive GT": {
      2010: "2010",
    },
    "M2 CS Coupe": {
      2020: "2020",
    },
    "750il": {
      2001: "2001",
    },
    "i3 BEV (60  Amp-hour battery)": {
      2017: "2017",
    },
    "328i Sports Wagon": {
      2011: "2011",
    },
    "323i": {
      2000: "2000",
    },
    "335cxi": {
      2008: "2008",
    },
    "i3 (94Ah)": {
      2018: "2018",
    },
    "i3s (94Ah)": {
      2018: "2018",
    },
    "M850i xDrive Gran Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "i3 BEV (94 Amp-hour battery)": {
      2017: "2017",
    },
    "M235i xDrive Gran Coupe": {
      2021: "2021",
    },
    "740il/740il Protection": {
      2000: "2000",
    },
    "323i Touring": {
      2000: "2000",
    },
    "ActiveHybrid 7i": {
      2011: "2011",
    },
    "X5 xDrive45e": {
      2021: "2021",
    },
    "528i Sport Wagon": {
      2000: "2000",
    },
    "535xi Sport Wagon": {
      2008: "2008",
    },
    "X3 3.0i": {
      2007: "2007",
    },
    "M8 Convertible": {
      2020: "2020",
    },
    "323ci": {
      2000: "2000",
    },
    "335i Convertible": {
      2013: "2013",
    },
    "M8 Coupe": {
      2020: "2020",
    },
    "i3 (94Ah) with Range Extender": {
      2018: "2018",
    },
    "740il": {
      2001: "2001",
    },
    "328i xDrive Sport Wagon": {
      2012: "2012",
    },
    "323i Convertible": {
      2000: "2000",
    },
    "ActiveHybrid 7Li": {
      2011: "2011",
    },
    "M3 Sedan": {
      2011: "2011",
    },
    "Z4 3.0si Coupe": {
      2006: "2006",
    },
    "M235i xDrive Convertible": {
      2016: "2016",
    },
    "i3s (94Ah) with Range Extender": {
      2018: "2018",
    },
    "330e xDrive": {
      2021: "2021",
    },
    "740i/740i Sport": {
      2000: "2000",
    },
    "528xi": {
      2008: "2008",
    },
    "M2 Competition": {
      2019: "2019",
    },
    "X5 4.8IS": {
      2004: "2004",
    },
    "X3 sDrive 28i": {
      2016: "2016",
    },
    "i3 REX  (94 Amp-hour battery)": {
      2017: "2017",
    },
    "M8 Competition Coupe": {
      2020: "2020",
    },
    "ActiveHybrid 7": {
      2012: "2012",
    },
    "M1 Coupe": {
      2011: "2011",
    },
    "750il/750il Protection": {
      2000: "2000",
    },
    "328i Convertible": {
      2013: "2013",
    },
    "M4 DTM Champions Edition": {
      2018: "2018",
    },
    "Alpina XB7": {
      2021: "2021",
    },
    "740Ld xDrive": {
      2015: "2015",
    },
    "M3 CS": {
      2018: "2018",
    },
    "M440i xDrive Coupe": {
      2021: "2021",
    },
  },
  "BMW Alpina": {
    "Roadster V8": {
      2003: "2003",
    },
    B7: {
      2008: "2008",
      2007: "2007",
    },
  },
  Bugatti: {
    Veyron: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2008: "2008",
      2006: "2006",
    },
    Chiron: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Divo: {
      2020: "2020",
    },
  },
  Buick: {
    Regal: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Enclave AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Enclave FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    LaCrosse: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "LaCrosse AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Encore AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Regal AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Encore: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "LaCrosse eAssist": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Lucerne: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    LeSabre: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Envision AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Verano: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Lacrosse/Allure": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Rendezvous AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Park Avenue": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Century: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Rendezvous FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Regal eAssist": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Cascada: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Rainier AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Terraza FWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Rainier 2WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Envision FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Regal TourX AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Terraza AWD": {
      2006: "2006",
      2005: "2005",
    },
    "Encore GX FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Encore FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Encore GX AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Lacrosse/Allure AWD": {
      2010: "2010",
    },
  },
  BYD: {
    e6: {
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
  },
  Cadillac: {
    "Escalade 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    CTS: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Escalade AWD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "SRX AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "SRX 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Limousine: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "CTS AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Funeral Coach / Hearse": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "CTS-V": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2010: "2010",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Escalade ESV 2WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Escalade Ext AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Escalade ESV AWD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Escalade 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2000: "2000",
    },
    XTS: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "ATS AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XTS Hearse": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "STS AWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "XTS AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    ATS: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XTS Limo": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    DTS: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    DeVille: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    STS: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    XLR: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Seville: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Escalade Hybrid 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "CTS Wagon AWD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "CT6 AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "CTS Wagon": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "XT5 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "ATS-V": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "XLR-V": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "STS-V": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    CT6: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "CTS Sedan AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "CTS Sedan": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Escalade Hybrid 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    XT5: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "XT4 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    Eldorado: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    ELR: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "XT4 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Armored Deville": {
      2005: "2005",
      2004: "2004",
    },
    "CT6 Plug-In": {
      2018: "2018",
      2017: "2017",
    },
    CT4: {
      2021: "2021",
      2020: "2020",
    },
    "CT5 AWD": {
      2021: "2021",
      2020: "2020",
    },
    CT5: {
      2021: "2021",
      2020: "2020",
    },
    "CT4 AWD": {
      2021: "2021",
      2020: "2020",
    },
    "XT6 AWD": {
      2021: "2021",
      2020: "2020",
    },
    "CT4 V AWD": {
      2021: "2021",
      2020: "2020",
    },
    "CT4 V": {
      2021: "2021",
      2020: "2020",
    },
    Catera: {
      2001: "2001",
      2000: "2000",
    },
    "CT5 V": {
      2021: "2021",
      2020: "2020",
    },
    "XT6 FWD": {
      2021: "2021",
      2020: "2020",
    },
    "CT5 V AWD": {
      2021: "2021",
      2020: "2020",
    },
    "XT5 FWD": {
      2021: "2021",
      2020: "2020",
    },
    SRX: {
      2016: "2016",
      2015: "2015",
    },
    "STS 2WD": {
      2005: "2005",
    },
    "ELR Sport": {
      2016: "2016",
    },
    "Escalade ESV 4WD": {
      2015: "2015",
    },
    "Armored DTS": {
      2006: "2006",
    },
    "CTS V": {
      2014: "2014",
    },
    "XT5 Hearse FWD": {
      2021: "2021",
    },
    "XT5 Hearse AWD": {
      2021: "2021",
    },
  },
  Chevrolet: {
    Malibu: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Impala: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Corvette: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Equinox FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Equinox AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Colorado 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Colorado 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Camaro: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tahoe 1500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Suburban 1500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tahoe 1500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Traverse AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Traverse FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Silverado K15 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Silverado C15 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Suburban 1500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Avalanche 1500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Silverado 15 Hybrid 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Silverado 15 Hybrid 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Avalanche 1500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    Volt: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Van 1500/2500 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Express 1500/2500 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sonic 5": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Colorado Crew Cab 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Colorado Crew Cab 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Cruze: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Spark: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    Sonic: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "TrailBlazer 2WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "TrailBlazer 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Malibu Hybrid": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Aveo 5": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Trax AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Colorado Cab Chassis inc 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Express 2500 2WD Passenger": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Express 3500 2WD Passenger": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Aveo: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2005: "2005",
      2004: "2004",
    },
    "Monte Carlo": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Silverado 1500 4WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Silverado 1500 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Astro AWD (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Van 1500/2500 AWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Express 1500 AWD Passenger": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    Cavalier: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Express 1500 2WD Passenger": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Venture FWD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tahoe Hybrid 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Astro 2WD (passenger)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "TrailBlazer Ext 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Suburban C1500 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "HHR FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Express 1500 AWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Tahoe K1500 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Suburban K1500 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Van 15/25 2WD Conversion": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Astro 2WD (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Van 1500 AWD Conversion": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Blazer 2WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Blazer 4WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tahoe Hybrid 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Colorado Cab Chassis inc 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Tahoe C1500 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Astro AWD (passenger)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Colorado Cab Chassis 2WD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "HHR Panel FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Trax: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Express 1500 AWD Cargo": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "S10 Pickup 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Colorado ZR2 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    Cobalt: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Spark ACTIV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Express 1500 2WD Cargo": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Cruze Eco": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Tracker 2WD Hardtop": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Express 1500 AWD Conversion Cargo": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "TrailBlazer Ext 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Express 1500 2WD Conversion Cargo": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Express 2500 2WD Conversion Cargo": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Tracker 4WD Hardtop": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Silverado C15 XFE 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Tracker 4WD Convertible": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "SSR Pickup 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Captiva FWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "City Express Cargo Van": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Malibu Maxx": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Tracker 2WD Convertible": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Silverado C15 Cab Chassis 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sonic RS": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Impala Police": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2001: "2001",
    },
    "Uplander FWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Tracker LT 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Optra: {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Silverado K15 Cab Chassis 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "S10 Pickup 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Bolt EV": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "TrailBlazer AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Cruze Premier": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Tracker ZR2 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    SS: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Suburban 1500 AWD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Tracker LT 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Cruze Hatchback": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Spark EV": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Classic: {
      2008: "2008",
      2005: "2005",
      2004: "2004",
    },
    "Tracker ZR2 4WD Convertible": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Astro 2WD (cargo) Conversion": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Suburban 2500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Optra Wagon": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Optra 5": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Sonic 5 RS": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Blazer FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Suburban 2500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Cruze Premier Hatchback": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Cavalier Dual-fuel": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Impala Limited": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Venture AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Blazer AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    Epica: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Astro AWD (cargo) Conversion": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Tahoe 1500 AWD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Captiva AWD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Prizm: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Silverado Cab Chassis 4WD": {
      2021: "2021",
      2020: "2020",
    },
    "Express 2500 2WD Cargo": {
      2012: "2012",
      2011: "2011",
    },
    "Cavalier (Bi-fuel CNG)": {
      2001: "2001",
      2000: "2000",
    },
    "Cobalt XFE": {
      2009: "2009",
      2008: "2008",
    },
    "Avalanche 1500 AWD": {
      2004: "2004",
      2003: "2003",
    },
    "Silverado 4WD TrailBoss": {
      2021: "2021",
      2020: "2020",
    },
    "Uplander AWD": {
      2006: "2006",
      2005: "2005",
    },
    "Silverado 1500 AWD": {
      2005: "2005",
      2004: "2004",
    },
    "Silverado 2WD": {
      2021: "2021",
      2020: "2020",
    },
    "S10 Pickup 2WD FFV": {
      2002: "2002",
      2001: "2001",
    },
    "Silverado Cab Chassis 2WD": {
      2021: "2021",
      2020: "2020",
    },
    Metro: {
      2001: "2001",
      2000: "2000",
    },
    "Silverado 2500 2WD": {
      2002: "2002",
      2000: "2000",
    },
    "Silverado 4WD": {
      2021: "2021",
      2020: "2020",
    },
    "Trax FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Malibu eAssist": {
      2014: "2014",
      2013: "2013",
    },
    "Impala Bi-Fuel  (CNG)": {
      2016: "2016",
      2015: "2015",
    },
    "Express 3500 2WD Cargo": {
      2012: "2012",
      2011: "2011",
    },
    "Silverado 2500 HD 2WD CNG": {
      2005: "2005",
      2004: "2004",
    },
    "Van 1500 AWD Cargo": {
      2009: "2009",
    },
    "Express Passenger (Bi-fuel)": {
      2004: "2004",
    },
    "Silverado K10 Cab Chassis 4WD": {
      2019: "2019",
    },
    "Malibu Limited": {
      2016: "2016",
    },
    "Silverado 2500 HD 2WD": {
      2005: "2005",
    },
    "Silverado LD C15 2WD": {
      2019: "2019",
    },
    "Suburban K10 4WD": {
      2014: "2014",
    },
    "Silverado Classic 1500 2WD": {
      2007: "2007",
    },
    "Silverado Classic 15 Hybrid 4WD": {
      2007: "2007",
    },
    "Trailblazer AWD": {
      2021: "2021",
    },
    "Cobalt XFE Coupe": {
      2010: "2010",
    },
    "Tahoe 2WD": {
      2021: "2021",
    },
    "Silverado LD K15 4WD": {
      2019: "2019",
    },
    "Express Cargo (dedicated CNG)": {
      2004: "2004",
    },
    "Cobalt SS Coupe": {
      2010: "2010",
    },
    "Cruze Limited": {
      2016: "2016",
    },
    "Suburban 2WD": {
      2021: "2021",
    },
    "Cobalt Sedan": {
      2010: "2010",
    },
    "Silverado K10 4WD": {
      2019: "2019",
    },
    "Tahoe 1500 XFE 2WD": {
      2009: "2009",
    },
    "Silverado K10 4WD TrailBoss": {
      2019: "2019",
    },
    "Van 15/25  2WD Conversion": {
      2005: "2005",
    },
    "Tahoe K10 4WD": {
      2014: "2014",
    },
    "Cruze Limited  Eco": {
      2016: "2016",
    },
    "Corvette Z06": {
      2019: "2019",
    },
    "Corvette ZR1": {
      2019: "2019",
    },
    "Silverado 2500 2WD (Bifuel)": {
      2003: "2003",
    },
    "Silverado C10 Cab Chassis 2WD": {
      2019: "2019",
    },
    "Van 1500 2WD Cargo": {
      2009: "2009",
    },
    "Impala eAssist": {
      2014: "2014",
    },
    "Silverado Classic 1500 4WD": {
      2007: "2007",
    },
    "Silverado C10 2WD": {
      2019: "2019",
    },
    "Silverado C10 XFE 2WD": {
      2019: "2019",
    },
    "Trailblazer FWD": {
      2021: "2021",
    },
    "Express Passenger (dedicated CNG)": {
      2004: "2004",
    },
    "Cobalt XFE Sedan": {
      2010: "2010",
    },
    "Tahoe C10 2WD": {
      2014: "2014",
    },
    "Lumina/Monte Carlo": {
      2000: "2000",
    },
    "Van 1500 2WD Conversion Cargo": {
      2009: "2009",
    },
    Lumina: {
      2001: "2001",
    },
    "Express Cargo (Bi-fuel)": {
      2004: "2004",
    },
    "Silverado SS 1500 AWD": {
      2003: "2003",
    },
    "Silverado Classic 15 Hybrid 2WD": {
      2007: "2007",
    },
    "Tahoe 4WD": {
      2021: "2021",
    },
    "Cobalt Coupe": {
      2010: "2010",
    },
    "Van 1500 AWD Conversion Cargo": {
      2009: "2009",
    },
    "Suburban C10 2WD": {
      2014: "2014",
    },
    "Silverado 2500 HD 4WD CNG": {
      2005: "2005",
    },
    "Suburban 4WD": {
      2021: "2021",
    },
  },
  Chrysler: {
    "300 AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    300: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2006: "2006",
    },
    "PT Cruiser": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Sebring: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sebring Convertible": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Town and Country": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Town and Country/Voyager/Grand Voy. 2WD": {
      2008: "2008",
      2007: "2007",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    200: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Sebring 4 Door": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Pacifica AWD": {
      2021: "2021",
      2020: "2020",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "300 M": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Town and Country/Voyager/Grand Voy. AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Pacifica: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "PT Cruiser Convertible": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Crossfire Roadster": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Crossfire Coupe": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Pacifica 2WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Pacifica Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "200 Convertible": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "300/SRT-8": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "300 SRT8": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Concorde: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Aspen 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "200 AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Aspen 2WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Concorde/LHS": {
      2004: "2004",
      2003: "2003",
    },
    LHS: {
      2001: "2001",
      2000: "2000",
    },
    "300C/SRT-8": {
      2006: "2006",
      2005: "2005",
    },
    "300C AWD": {
      2006: "2006",
      2005: "2005",
    },
    Voyager: {
      2021: "2021",
      2020: "2020",
    },
    Crossfire: {
      2004: "2004",
    },
    "Pacifica FWD": {
      2008: "2008",
    },
    Prowler: {
      2002: "2002",
    },
    "Voyager/Town and Country 2WD": {
      2005: "2005",
    },
    "Aspen HEV": {
      2009: "2009",
    },
    "Town and Country 2WD": {
      2006: "2006",
    },
    Cirrus: {
      2000: "2000",
    },
    "Sebring AWD": {
      2008: "2008",
    },
  },
  "CODA Automotive": {
    CODA: {
      2013: "2013",
      2012: "2012",
    },
  },
  Daewoo: {
    Lanos: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Nubira: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Nubira Station Wagon": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Leganza: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Kalos: {
      2004: "2004",
    },
    Lacetti: {
      2004: "2004",
    },
    Magnus: {
      2004: "2004",
    },
  },
  Dodge: {
    Charger: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Charger AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Ram 1500 Pickup 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Ram 1500 Pickup 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Durango 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Durango 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Challenger: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Dakota Pickup 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Dakota Pickup 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Grand Caravan": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Viper Convertible": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Caravan/Grand Caravan 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Journey AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Avenger: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2000: "2000",
    },
    "Durango RWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Durango AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Stratus 4 Door": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Challenger SRT8": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Stratus: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Caliber: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Viper Coupe": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Journey FWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Charger SRT8": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Intrepid: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Nitro 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Nitro 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Journey: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Caravan/Grand Caravan AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Challenger SRT": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    Dart: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Magnum AWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Dart Aero": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Neon/SRT-4/SX 2.0": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Challenger GT": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    Neon: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Durango SRT AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Ram Van 2500 2WD CNG": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Dart GT": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Ram Van 2500 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Magnum: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Ram Van 1500 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Charger SRT": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Viper SRT": {
      2016: "2016",
      2015: "2015",
    },
    "Journey  2WD": {
      2010: "2010",
      2009: "2009",
    },
    "Ram Wagon 1500 2WD": {
      2002: "2002",
      2001: "2001",
    },
    "Ram Wagon 2500 2WD CNG": {
      2002: "2002",
      2001: "2001",
    },
    "Ram Wagon 2500 2WD": {
      2002: "2002",
      2001: "2001",
    },
    Viper: {
      2017: "2017",
    },
    "B2500 Wagon 2WD": {
      2000: "2000",
    },
    "Charger SRT Widebody": {
      2020: "2020",
    },
    "B1500 Wagon 2WD": {
      2000: "2000",
    },
    "Journey  AWD": {
      2010: "2010",
    },
    "Caravan/Grand Caravan FWD": {
      2009: "2009",
    },
    "Durango HEV": {
      2009: "2009",
    },
    "Challenger AWD": {
      2020: "2020",
    },
    "Magnum 2WD": {
      2005: "2005",
    },
    "Challenger Widebody": {
      2020: "2020",
    },
    "Journey  4WD": {
      2009: "2009",
    },
    "Challenger SRT Widebody": {
      2020: "2020",
    },
    "B1500 Van 2WD": {
      2000: "2000",
    },
    "Avenger AWD": {
      2008: "2008",
    },
    "Caliber AWD": {
      2008: "2008",
    },
    "B2500 Van 2WD": {
      2000: "2000",
    },
    "Charger Widebody": {
      2020: "2020",
    },
  },
  Ferrari: {
    "612 Scaglietti": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    California: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    F430: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "458 Italia": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
    },
    FF: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "488 GTB": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "812 Superfast": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    GTC4Lusso: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "California T": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "456 MGT/MGA": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "360 Modena/Spider": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "599 GTB Fiorano": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    F12: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "488 Spider": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "458 Spider": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "GTC4Lusso T": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Portofino: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "F8 Tributo": {
      2021: "2021",
      2020: "2020",
    },
    "488 Pista Spider": {
      2020: "2020",
      2019: "2019",
    },
    "458 Speciale": {
      2015: "2015",
      2014: "2014",
    },
    "360 Modena/Spider/Challenge": {
      2005: "2005",
      2004: "2004",
    },
    "488 Pista": {
      2020: "2020",
      2019: "2019",
    },
    "575 M Maranello": {
      2004: "2004",
      2003: "2003",
    },
    "F12 tdf": {
      2017: "2017",
      2016: "2016",
    },
    "F12 Berlinetta": {
      2017: "2017",
    },
    "Portofino M": {
      2021: "2021",
    },
    "550 Maranello/Barchetta": {
      2001: "2001",
    },
    "F60 America": {
      2016: "2016",
    },
    "599 SA Aperta": {
      2011: "2011",
    },
    "599 GTO": {
      2011: "2011",
    },
    575: {
      2002: "2002",
    },
    "LaFerrari Aperta": {
      2017: "2017",
    },
    "575 M Maranello and Superamerica": {
      2005: "2005",
    },
    "LaFerrari Hybrid": {
      2014: "2014",
    },
    F141: {
      2006: "2006",
    },
    "458 Italia Coupe": {
      2012: "2012",
    },
    "458 Italia Spider": {
      2012: "2012",
    },
    "599 GTB": {
      2007: "2007",
    },
    "550 Maranello": {
      2000: "2000",
    },
    "F8 Spider": {
      2021: "2021",
    },
    "Enzo Ferrari": {
      2003: "2003",
    },
    "812 GTS": {
      2021: "2021",
    },
    "458 Speciale Spider": {
      2015: "2015",
    },
    Roma: {
      2021: "2021",
    },
    "SF90 Stradale Coupe": {
      2021: "2021",
    },
  },
  Fiat: {
    500: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "500 Abarth": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "500e": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "500 L": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "124 Spider": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "500 X": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "500 X AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "500X AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "500 Cabrio": {
      2012: "2012",
    },
  },
  Fisker: {
    Karma: {
      2012: "2012",
    },
  },
  Ford: {
    Mustang: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "F150 Pickup 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "F150 Pickup 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "F150 Pickup 2WD FFV": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Expedition 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Escape FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "F150 Pickup 4WD FFV": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Fusion AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Edge FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Edge AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Expedition 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Taurus FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Taurus AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Explorer 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Fusion FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Explorer 2WD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Explorer 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Flex FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Escape 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Flex AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Fusion Hybrid FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Explorer AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Ranger Pickup 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Mustang Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Ranger Pickup 2WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Focus FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Escape AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Explorer FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Focus: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Crown Victoria": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Taurus: {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Transit Connect Wagon LWB FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Fiesta FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Fusion Energi Plug-in Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Focus Station Wagon": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Escape Hybrid 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "E150 Club Wagon": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Crown Victoria Police": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Focus Electric": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Explorer AWD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Transit Connect Wagon FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Escape Hybrid FWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Taurus FWD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Explorer Sport Trac 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Taurus AWD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "E150 Econoline 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Explorer Sport Trac 4WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Transit Connect Van 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Fiesta SFE FWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Focus FWD FFV": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "E250 Econoline 2WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Taurus Wagon": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Expedition 2WD FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "C-MAX Hybrid FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Escape FWD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Transit Connect Van FFV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Fiesta ST FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Transit Connect Wagon LWB FFV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Escape 2WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Crown Victoria CNG": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Transit T150 Wagon FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Expedition 4WD FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Transit T150 Wagon": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    GT: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2005: "2005",
    },
    "Shelby GT350 Mustang": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "E350 Van FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "F150 Dual-fuel 2WD (CNG)": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "E150 Wagon FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "F150 Dual-fuel 4WD (LPG)": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Ranger 4WD": {
      2020: "2020",
      2019: "2019",
      2011: "2011",
      2010: "2010",
    },
    "Freestar Cargo Van FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "EcoSport FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "E150 Van FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "F150 Raptor Pickup 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Thunderbird: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Ranger 2WD FFV": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "EcoSport AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "F150 CNG": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "F150 RAPTOR 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "F150 5.0L 2WD FFV GVWR>7599 LBS": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Ranger 2WD": {
      2020: "2020",
      2019: "2019",
      2011: "2011",
      2010: "2010",
    },
    "E350 Van": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "F150 2WD BASE PAYLOAD LT TIRE": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Freestar Wagon FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "E250 Van FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "C-MAX Energi Plug-in Hybrid": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Crown Victoria FFV": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Explorer 4WD FFV": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "F150 Dual-fuel 2WD (LPG)": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Windstar FWD Wagon": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "E350 Wagon FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Escort ZX2": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "F150 Dual-fuel 4WD (CNG)": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "E350 Wagon": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "F150 5.0L 4WD FFV GVWR>7599 LBS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Explorer Sport 4WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Explorer Sport 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Transit Connect Wagon FFV": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Escort: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Freestyle AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Freestyle FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Fusion S FWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "F150 2WD FFV BASE PAYLOAD LT": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Expedition EL 4WD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Focus SFE FWD FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Focus RS AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Fusion: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Focus ST FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Five Hundred AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "F150 2.7L 2WD GVWR>6649 LBS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "F150 Pickup 4WD XL/XLT": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "F150 3.5L 4WD GVWR>7599 LBS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "F150 3.5L 2WD GVWR>7599 LBS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Expedition MAX 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "F150 4WD BASE PAYLOAD LT TIRE": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Windstar FWD Cargo Van": {
      2003: "2003",
      2002: "2002",
      2000: "2000",
    },
    "F150 2.7L 4WD GVWR>6799 LBS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Expedition MAX 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Fusion AWD FFV": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Transit Connect USPS": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Fusion FWD FFV": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Five Hundred FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Mustang Performance Package": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Expedition EL 2WD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "F150 Pickup 2WD Limited": {
      2020: "2020",
      2019: "2019",
    },
    "Explorer HEV AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Ranger 2WD Incomplete": {
      2020: "2020",
      2019: "2019",
    },
    "Explorer USPS Electric": {
      2002: "2002",
      2001: "2001",
    },
    "Explorer RWD": {
      2021: "2021",
      2020: "2020",
    },
    "Explorer Sport Trac 2WD FFV": {
      2005: "2005",
      2004: "2004",
    },
    "F150 4WD FFV BASE PAYLOAD LT": {
      2019: "2019",
      2018: "2018",
    },
    "F150 STX SE 2WD FFV": {
      2008: "2008",
      2007: "2007",
    },
    "F150 STX SE 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "Mustang Bullitt": {
      2020: "2020",
      2019: "2019",
    },
    "Explorer Sport Trac 4WD FFV": {
      2005: "2005",
      2004: "2004",
    },
    "Taurus X AWD": {
      2009: "2009",
      2008: "2008",
    },
    "Taurus X FWD": {
      2009: "2009",
      2008: "2008",
    },
    "Explorer FFV AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Fusion Hybrid Taxi": {
      2020: "2020",
      2019: "2019",
    },
    "Escape 4WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Transit Connect Van": {
      2013: "2013",
      2012: "2012",
    },
    "Focus SFE FWD": {
      2015: "2015",
      2012: "2012",
    },
    "F150 Pickup 4WD Limited": {
      2020: "2020",
      2019: "2019",
    },
    "F150 4WD FFV BASE PAYLOAD LT TIRE": {
      2018: "2018",
      2017: "2017",
    },
    "Transit Connect": {
      2011: "2011",
      2010: "2010",
    },
    Contour: {
      2000: "2000",
    },
    "Mustang HO Coupe": {
      2020: "2020",
    },
    "Explorer Sport 2WD FFV": {
      2001: "2001",
    },
    "Fusion Special Service Vehicle PHEV": {
      2019: "2019",
    },
    "Explorer USPS 4WD": {
      2001: "2001",
    },
    "GT 2WD": {
      2006: "2006",
    },
    "Escape AWD FFV": {
      2012: "2012",
    },
    "F150 3.5L 4WD GVWR>7599 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    Fiesta: {
      2011: "2011",
    },
    "Shelby GT500 Mustang": {
      2020: "2020",
    },
    "Explorer USPS 4WD FFV": {
      2001: "2001",
    },
    "Mustang HO Convertible": {
      2020: "2020",
    },
    "E250 Econoline 2WD CNG": {
      2000: "2000",
    },
    "F150 Pickup 2WD CNG": {
      2000: "2000",
    },
    "Th!nk": {
      2001: "2001",
    },
    "F150 5.0L 4WD FFV GVWR>7599 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    "Lightning Pickup 2WD": {
      2000: "2000",
    },
    "Taurus Wagon FFV": {
      2001: "2001",
    },
    "Explorer USPS 2WD": {
      2001: "2001",
    },
    "Escape Hybrid AWD": {
      2012: "2012",
    },
    "Explorer HEV RWD": {
      2020: "2020",
    },
    "Transit T150 Wagon 4WD FFV": {
      2020: "2020",
    },
    "F150 5.0L 4WD GVWR>7599 LBS": {
      2018: "2018",
    },
    "E250 CNG": {
      2001: "2001",
    },
    "F150 5.0L 2WD GVWR>7599 LBS": {
      2018: "2018",
    },
    "Taurus FFV": {
      2001: "2001",
    },
    "Windstar FWD Van": {
      2001: "2001",
    },
    "Escape FWD HEV": {
      2020: "2020",
    },
    "F150 2WD FFV BASE PAYLOAD LT TIRE": {
      2018: "2018",
    },
    "C-MAX Energi Plug-In Hybrid": {
      2017: "2017",
    },
    "Escape FWD PHEV": {
      2020: "2020",
    },
    "F150 5.0L 2WD FFV GVWR>7599 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    "Fiesta SFE": {
      2011: "2011",
    },
    "Ranger 4WD FFV": {
      2000: "2000",
    },
    "Explorer USPS 2WD FFV": {
      2001: "2001",
    },
    "Postal Vehicle": {
      2000: "2000",
    },
    "F150 3.5L 2WD GVWR>7599 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    "F150 SFE 2WD": {
      2009: "2009",
    },
    "Escape AWD HEV": {
      2020: "2020",
    },
    "Expedition Limo. 2WD FFV": {
      2011: "2011",
    },
    "Escape Hybrid 2WD": {
      2005: "2005",
    },
    "Transit T150 Wagon 2WD FFV": {
      2020: "2020",
    },
    "F150 2.7L 4WD GVWR>6799 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    "Explorer Sport 4WD FFV": {
      2001: "2001",
    },
    "Fusion Special Service PHEV": {
      2020: "2020",
    },
    "F150 2.7L 2WD GVWR>6649 LBS PAYLOAD PACKAGE": {
      2016: "2016",
    },
    "Transit Connect Van FWD": {
      2021: "2021",
    },
  },
  Genesis: {
    "G90 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "G90 RWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "G80 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "G80 RWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "G70 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "G70 RWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "GV80 RWD": {
      2021: "2021",
    },
    "GV80 AWD": {
      2021: "2021",
    },
  },
  GMC: {
    "Canyon 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Canyon 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Acadia AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Acadia FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Yukon XL 1500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon 1500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon 1500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sierra C15 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Savana 1500 AWD (Passenger)": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Sierra K15 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Yukon XL 1500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Terrain FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Sierra 15 Hybrid 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Terrain AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Sierra 15 Hybrid 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Savana 1500  AWD Conversion (cargo)": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Canyon Crew Cab 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Canyon Crew Cab 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Savana 1500/2500 2WD (cargo)": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon Denali 1500 AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2002: "2002",
    },
    "Savana 1500/2500 2WD (Passenger)": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2000: "2000",
    },
    "Envoy 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Envoy 2WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Savana 3500 2WD (Passenger)": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Canyon Cab Chassis Inc 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Sierra 1500 4WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sierra 1500 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon XL 1500 AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Savana 2500 2WD (Passenger)": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Safari AWD (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon 1500 Hybrid 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Sierra K15 AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Savana 1500/2500 AWD (cargo)": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Yukon C1500 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Safari AWD (Passenger)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Savana 1500  AWD (cargo)": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Canyon Cab Chassis Inc 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Envoy XL 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Yukon 1500 Hybrid 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Safari 2WD (Passenger)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon K1500 XL 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Savana 1500 2WD (cargo)": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Savana 1500 2WD (Passenger)": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Yukon C1500 XL 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Yukon K1500 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Safari 2WD (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Savana 15/25 2WD Conversion (cargo)": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Jimmy 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Savana 2500 2WD Conversion (cargo)": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Jimmy 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Canyon Cab Chassis 2WD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Envoy XL 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Sierra C15 XFE 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Savana 1500 2WD Conversion (cargo)": {
      2014: "2014",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Sonoma 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sierra K15 Cab Chassis 4WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sierra C15 Cab Chassis 2WD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sonoma 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Yukon 1500 AWD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Sierra 1500 AWD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Savana 15/25 AWD Conversion (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Safari AWD Conversion (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Safari 2WD Conversion (cargo)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Yukon XL 2500 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Yukon XL 2500 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Yukon Denali 1500 Hybrid 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Sierra Denali 1500 AWD": {
      2003: "2003",
      2002: "2002",
    },
    "Savana 3500 2WD (cargo)": {
      2012: "2012",
      2011: "2011",
    },
    "Sonoma 2WD (FFV)": {
      2002: "2002",
      2001: "2001",
    },
    "Sierra Cab Chassis 4WD": {
      2021: "2021",
      2020: "2020",
    },
    "Sierra 4WD AT4": {
      2021: "2021",
      2020: "2020",
    },
    "Envoy XUV 2WD": {
      2006: "2006",
      2005: "2005",
    },
    "Sierra 2WD": {
      2021: "2021",
      2020: "2020",
    },
    "Sierra 4WD": {
      2021: "2021",
      2020: "2020",
    },
    "Savana 2500 2WD (cargo)": {
      2012: "2012",
      2011: "2011",
    },
    "Sierra Cab Chassis 2WD": {
      2021: "2021",
      2020: "2020",
    },
    "Envoy XUV 4WD": {
      2006: "2006",
      2005: "2005",
    },
    "Yukon 2WD": {
      2021: "2021",
    },
    "Savana 1500/2500  2WD (Passenger)": {
      2001: "2001",
    },
    "Yukon Denali K10 AWD": {
      2014: "2014",
    },
    "Sierra Classic 15 Hybrid 4WD": {
      2007: "2007",
    },
    "Savana Passenger (Bi-fuel)": {
      2004: "2004",
    },
    "Savana (cargo) (Bi-fuel)": {
      2004: "2004",
    },
    "Sierra K10 4WD": {
      2019: "2019",
    },
    "Yukon Denali XL 1500 AWD": {
      2002: "2002",
    },
    "Sierra C10 XFE 2WD": {
      2019: "2019",
    },
    "Acadia Limited AWD": {
      2017: "2017",
    },
    "Yukon XL C10 2WD": {
      2014: "2014",
    },
    "Sierra Classic 1500 AWD": {
      2007: "2007",
    },
    "Yukon K10 4WD": {
      2014: "2014",
    },
    "Savana 1500  2WD Conversion (cargo)": {
      2013: "2013",
    },
    "Sierra LTD K15 4WD": {
      2019: "2019",
    },
    "Sierra C10 Cab Chassis 2WD": {
      2019: "2019",
    },
    "Yukon 1500 XFE 2WD": {
      2009: "2009",
    },
    "Sierra 2500 HD 4WD CNG": {
      2005: "2005",
    },
    "Yukon C10 2WD": {
      2014: "2014",
    },
    "Sierra C10 2WD": {
      2019: "2019",
    },
    "Sierra 2500 2WD": {
      2000: "2000",
    },
    "Yukon XL K10 AWD": {
      2014: "2014",
    },
    "Sierra K10 Cab Chassis 4WD": {
      2019: "2019",
    },
    "Sierra 2500 HD 2WD CNG": {
      2005: "2005",
    },
    "Sierra K10 4WD AT4": {
      2019: "2019",
    },
    "Sierra LTD C15 2WD": {
      2019: "2019",
    },
    "Acadia Limited FWD": {
      2017: "2017",
    },
    "Savana 1500 AWD (cargo)": {
      2009: "2009",
    },
    "Sierra 2500 Hd 2WD": {
      2005: "2005",
    },
    "Yukon XL 2WD": {
      2021: "2021",
    },
    "Yukon XL 4WD": {
      2021: "2021",
    },
    "Sierra Classic 15 Hybrid 2WD": {
      2007: "2007",
    },
    "Sierra Classic 1500 4WD": {
      2007: "2007",
    },
    "Savana Cargo (dedicated CNG)": {
      2004: "2004",
    },
    "Sierra Classic 1500 2WD": {
      2007: "2007",
    },
    "Savana 1500 AWD Conversion (cargo)": {
      2009: "2009",
    },
    "Savana Passenger (dedicated CNG)": {
      2004: "2004",
    },
    "Yukon XL K10 4WD": {
      2014: "2014",
    },
    "Yukon 4WD": {
      2021: "2021",
    },
  },
  Honda: {
    Accord: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Odyssey: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CR-V 4WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CR-V 2WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Civic: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Insight: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Pilot 4WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Civic Hybrid": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Fit: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Civic CNG": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Pilot 2WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Accord Hybrid": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    S2000: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Ridgeline Truck 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Element 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Element 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "CR-Z": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Civic 4Dr": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Civic 5Dr": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "HR-V AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "HR-V FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Pilot FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Pilot AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Civic 2Dr": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Accord Coupe": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "CR-V FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Clarity Plug-in Hybrid": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Crosstour 4WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Ridgeline AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Civic HF": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Ridgeline FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Crosstour 2WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "CR-V AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Civic Natural Gas": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Clarity EV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Passport FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Passport 2WD": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Passport AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Accord 2.0T Sport/Touring": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Civic HX": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Passport 4WD": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Insight Touring": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Civic 5Dr - Type R": {
      2018: "2018",
      2017: "2017",
    },
    "Fit EV": {
      2014: "2014",
      2013: "2013",
    },
    "Accord Crosstour 2WD": {
      2011: "2011",
      2010: "2010",
    },
    "Accord Crosstour 4WD": {
      2011: "2011",
      2010: "2010",
    },
    "Civic 5Dr Sport": {
      2018: "2018",
      2017: "2017",
    },
    Prelude: {
      2001: "2001",
      2000: "2000",
    },
    "Civic Si 4Dr": {
      2018: "2018",
      2017: "2017",
    },
    "Civic Si 2Dr": {
      2018: "2018",
      2017: "2017",
    },
    "HR-V 4WD": {
      2016: "2016",
    },
    "Accord Plug-in Hybrid": {
      2014: "2014",
    },
    "HR-V 2WD": {
      2016: "2016",
    },
    "Accord Sport/Touring": {
      2021: "2021",
    },
    "Accord Hybrid Sport/Touring": {
      2021: "2021",
    },
  },
  Hummer: {
    "H3 4WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "H3T 4WD": {
      2010: "2010",
      2009: "2009",
    },
  },
  Hyundai: {
    Elantra: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Sonata: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Accent: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Santa Fe 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Santa Fe 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Azera: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Tucson 2WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Sonata Hybrid": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Tucson 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    Tiburon: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Veloster: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Elantra GT": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Santa Fe AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Genesis Coupe": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Santa Fe FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Tucson AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Veracruz 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Tucson FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Veracruz 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Accent/Brio": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Genesis: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    Equus: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Entourage: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Santa Fe Sport Ultimate FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Santa Fe Sport AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Santa Fe Ultimate AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    XG350: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Santa Fe Ultimate FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Elantra Touring": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Elantra SE": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Kona FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Ioniq Electric": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Ioniq Blue": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Sonata Hybrid SE": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Santa Fe Sport FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sonata Plug-in Hybrid": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Kona AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Ioniq: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Sonata Limited": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Veloster N": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Azera Limited": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Elantra Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Elantra Limited": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Ioniq Plug-in Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Sonata Sport Limited": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sonata Hybrid Limited": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Genesis AWD": {
      2016: "2016",
      2015: "2015",
    },
    "Tucson Eco FWD": {
      2017: "2017",
      2016: "2016",
    },
    "Tucson Eco AWD": {
      2017: "2017",
      2016: "2016",
    },
    Venue: {
      2021: "2021",
      2020: "2020",
    },
    "Kona Electric": {
      2020: "2020",
      2019: "2019",
    },
    "Palisade FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Accent Blue": {
      2011: "2011",
      2010: "2010",
    },
    "Sonata Hybrid Blue": {
      2021: "2021",
      2020: "2020",
    },
    "Elantra Blue": {
      2013: "2013",
      2010: "2010",
    },
    "Santa Fe Sport 4WD": {
      2014: "2014",
      2013: "2013",
    },
    "Santa Fe Sport Ultimate AWD": {
      2018: "2018",
      2017: "2017",
    },
    "Palisade AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Santa Fe Sport 2WD": {
      2014: "2014",
      2013: "2013",
    },
    "Sonata SE": {
      2019: "2019",
      2018: "2018",
    },
    "Genesis R-Spec": {
      2013: "2013",
      2012: "2012",
    },
    "Genesis RWD": {
      2016: "2016",
      2015: "2015",
    },
    XG300: {
      2001: "2001",
    },
    "Elantra Hatchback": {
      2004: "2004",
    },
    "Santa Fe XL AWD": {
      2019: "2019",
    },
    "Santa Fe Ultimate 2WD": {
      2014: "2014",
    },
    "Santa Fe XL Ultimate AWD": {
      2019: "2019",
    },
    "Elantra Wagon": {
      2000: "2000",
    },
    "Santa Fe XL Ultimate FWD": {
      2019: "2019",
    },
    "Santa Fe Ultimate 4WD": {
      2014: "2014",
    },
    "Santa Fe XL FWD": {
      2019: "2019",
    },
    "Genesis R Spec": {
      2014: "2014",
    },
  },
  Infiniti: {
    "FX35 AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "QX56 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "QX56 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "FX35 RWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Q50: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Q50 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "QX80 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "QX80 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "QX50 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    QX50: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    M45: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2004: "2004",
      2003: "2003",
    },
    "QX60 FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Q45: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "QX60 AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "G37 Coupe": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    G35: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Q70 AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "FX45 AWD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Q70: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Q60: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
    },
    EX35: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "G37 Convertible": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Q50 Hybrid": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    M35: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "G37x Coupe": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Q50 Hybrid AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Q70 Hybrid": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Q60 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
    },
    G37x: {
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
    },
    "Q60 Red Sport AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Q60 Red Sport": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "QX60 Hybrid AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Q50 Red Sport  AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "QX4 4WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "QX70 AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "QX70 RWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "QX60 Hybrid FWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "FX50 AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
    },
    M35x: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Q50 Red Sport": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    G37: {
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
    },
    "EX35 AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    QX30: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    M56x: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    G20: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    M37x: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    M56: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    M45x: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    M37: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    I35: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "QX4 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "QX30 AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Q50a AWD": {
      2015: "2015",
      2014: "2014",
    },
    I30: {
      2001: "2001",
      2000: "2000",
    },
    "Q50S Hybrid AWD": {
      2015: "2015",
      2014: "2014",
    },
    "Q50S Red Sport  AWD": {
      2017: "2017",
      2016: "2016",
    },
    M35h: {
      2013: "2013",
      2012: "2012",
    },
    "Q50S Hybrid": {
      2015: "2015",
      2014: "2014",
    },
    G25x: {
      2012: "2012",
      2011: "2011",
    },
    G35x: {
      2008: "2008",
      2007: "2007",
    },
    G25: {
      2012: "2012",
      2011: "2011",
    },
    "Q60 Convertible": {
      2015: "2015",
      2014: "2014",
    },
    Q50a: {
      2015: "2015",
      2014: "2014",
    },
    "Q50S Red Sport": {
      2017: "2017",
      2016: "2016",
    },
    "QX80 4WD (over 6000 lbs curb weight)": {
      2015: "2015",
    },
    Q40: {
      2015: "2015",
    },
    "Q60 Coupe": {
      2014: "2014",
    },
    "Q50S AWD": {
      2016: "2016",
    },
    EX37: {
      2013: "2013",
    },
    "EX37 AWD": {
      2013: "2013",
    },
    "FX35 2WD": {
      2003: "2003",
    },
    "Q60 AWD Coupe": {
      2014: "2014",
    },
    FX50: {
      2010: "2010",
    },
    "FX37 AWD": {
      2013: "2013",
    },
    "G35 Coupe": {
      2007: "2007",
    },
    "Q60S Red Sport AWD": {
      2017: "2017",
    },
    "Q45 Sport": {
      2006: "2006",
    },
    "Q40 AWD": {
      2015: "2015",
    },
    "JX35 AWD": {
      2013: "2013",
    },
    "JX35 FWD": {
      2013: "2013",
    },
    "Q60S Red Sport": {
      2017: "2017",
    },
    Q50S: {
      2016: "2016",
    },
    "FX37 RWD": {
      2013: "2013",
    },
  },
  Isuzu: {
    "Rodeo 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Rodeo 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Ascender 5-passenger 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Ascender 5-passenger 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Axiom 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Rodeo Sport 4WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Trooper 2WD": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Rodeo Sport 2WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Axiom 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Ascender 7-passenger 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Ascender 4WD": {
      2004: "2004",
      2003: "2003",
    },
    "i-370 Extended Cab 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "i-370 Crew Cab 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "i-290 Extended Cab 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "Hombre Pickup 2WD (FFV)": {
      2002: "2002",
      2001: "2001",
    },
    "Ascender 2WD": {
      2004: "2004",
      2003: "2003",
    },
    "Hombre Pickup 2WD": {
      2001: "2001",
      2000: "2000",
    },
    "Hombre Pickup 4WD": {
      2001: "2001",
      2000: "2000",
    },
    "Ascender 7-passenger 2WD": {
      2006: "2006",
      2005: "2005",
    },
    "i-370 Crew Cab 4WD": {
      2008: "2008",
      2007: "2007",
    },
    "Trooper 4WD": {
      2002: "2002",
      2001: "2001",
    },
    "Amigo 2WD": {
      2000: "2000",
    },
    "Amigo 4WD": {
      2000: "2000",
    },
    Trooper: {
      2000: "2000",
    },
    Vehicross: {
      2000: "2000",
    },
    "Vehicross 4WD": {
      2001: "2001",
    },
    "i-280 Extended Cab 2WD": {
      2006: "2006",
    },
    "i-350 Crew Cab 4WD": {
      2006: "2006",
    },
  },
  Jaguar: {
    XF: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    XJ: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    XKR: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XKR Convertible": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    XK: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "XK Convertible": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    XJR: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XF AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "F-Type S Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Super V8": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2003: "2003",
      2002: "2002",
    },
    "XK8 Convertible": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "F-Type Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    XK8: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XJ AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "F-Type S Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "X-Type": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    XJ8: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "S-Type 4.2 Litre": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "F-Type S AWD Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "F-Type Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    XJ8L: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2001: "2001",
      2000: "2000",
    },
    "XJL AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "S-Type 3.0 Litre": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "S-Type R": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    XJL: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "F-Type S AWD Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Vanden Plas": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Vdp Lwb": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "F-Pace": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "XE AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "F-Type R AWD Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    XE: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "F-Type R AWD Convertible": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "X-Type Sport Brake": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "XJ FFV": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XF FFV": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XJL FFV": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XJ LWB": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "XF Sportbrake AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "XJ Sport": {
      2003: "2003",
      2002: "2002",
    },
    "I-Pace": {
      2020: "2020",
      2019: "2019",
    },
    "S-Type": {
      2002: "2002",
      2001: "2001",
    },
    "E-Pace": {
      2020: "2020",
      2018: "2018",
    },
    "F-Type SVR AWD Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "E-Pace P300": {
      2020: "2020",
      2019: "2019",
    },
    "Vanden Plas S/C": {
      2001: "2001",
      2000: "2000",
    },
    "XJR 4.2 Litre": {
      2005: "2005",
      2004: "2004",
    },
    "XJ8 4.2 Litre": {
      2005: "2005",
      2004: "2004",
    },
    "F-Type V8 S Convertible": {
      2015: "2015",
      2014: "2014",
    },
    "S-type (X200) V8": {
      2000: "2000",
    },
    Vdp: {
      2009: "2009",
    },
    "S-type (X200) V6": {
      2000: "2000",
    },
    "XE AWD (296 Hp)": {
      2018: "2018",
    },
    "XF AWD 30t": {
      2020: "2020",
    },
    "F-Pace 30t": {
      2020: "2020",
    },
    XJ8l: {
      2005: "2005",
    },
    "XF (296 Hp)": {
      2018: "2018",
    },
    "F-Type SVR AWD Coupe GEN2": {
      2019: "2019",
    },
    "F-Type AWD Convertible": {
      2020: "2020",
    },
    "E-Pace P250": {
      2019: "2019",
    },
    "F-Type R Convertible": {
      2016: "2016",
    },
    "F-Type SVR AWD Convertible GEN2": {
      2019: "2019",
    },
    "XJ LWB AWD": {
      2013: "2013",
    },
    "XF Supercharged": {
      2009: "2009",
    },
    "F-Pace (296 Hp)": {
      2018: "2018",
    },
    "XF 30t": {
      2020: "2020",
    },
    "XF AWD (296 Hp)": {
      2018: "2018",
    },
    "XE AWD 30t": {
      2020: "2020",
    },
    "F-Type R Coupe": {
      2015: "2015",
    },
    "F-Type AWD Coupe": {
      2020: "2020",
    },
    "E-Pace (296 Hp)": {
      2018: "2018",
    },
    "Vdp 4.2 Litre": {
      2004: "2004",
    },
    "XE 30t": {
      2020: "2020",
    },
    "XE (296 Hp)": {
      2018: "2018",
    },
    "F-Type SVR AWD Convertible": {
      2021: "2021",
      2020: "2020",
    },
  },
  Jeep: {
    "Grand Cherokee 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Grand Cherokee 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Wrangler 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Compass 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Patriot 4WD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Cherokee 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2001: "2001",
      2000: "2000",
    },
    "Wrangler Unlimited 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cherokee 4WD Active Drive II": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Cherokee Trailhawk 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Compass FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Cherokee FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Compass 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Renegade 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Patriot 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Renegade 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Liberty 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2002: "2002",
    },
    "Liberty 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2002: "2002",
    },
    "Liberty/Cherokee 2WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Commander 4WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Commander 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Liberty/Cherokee 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Wrangler/TJ 4WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Patriot FWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Grand Cherokee SRT 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Wrangler 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Grand Cherokee SRT8 4WD": {
      2016: "2016",
      2015: "2015",
      2012: "2012",
    },
    "Renegade Trailhawk 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Grand Cherokee SRT8 AWD": {
      2010: "2010",
      2009: "2009",
    },
    "Cherokee 2WD": {
      2001: "2001",
      2000: "2000",
    },
    "Grand Cherokee Trackhawk 4WD": {
      2020: "2020",
      2019: "2019",
    },
    "Grand Cherokee SRT8": {
      2014: "2014",
      2013: "2013",
    },
    "New Wrangler 4WD": {
      2018: "2018",
    },
    "Grand Cherokee TrackHawk 4WD": {
      2018: "2018",
    },
    "New Compass 2WD": {
      2017: "2017",
    },
    "Gladiator 4WD": {
      2021: "2021",
    },
    "Wrangler 4dr 4WD": {
      2021: "2021",
    },
    "Gladiator EcoDiesel 4WD": {
      2021: "2021",
    },
    "Gladiator Pickup 4WD": {
      2020: "2020",
    },
    "Wrangler Rubic 4dr EcoDiesel 4WD": {
      2021: "2021",
    },
    "New Wrangler Unlimited 4WD": {
      2018: "2018",
    },
    "New Compass 4WD": {
      2017: "2017",
    },
    "Wrangler 4dr EcoDiesel 4WD": {
      2021: "2021",
    },
    "Wrangler 2dr 4WD": {
      2021: "2021",
    },
    "Gladiator Rubicon EcoDiesel 4WD": {
      2021: "2021",
    },
  },
  Karma: {
    Revero: {
      2019: "2019",
      2018: "2018",
    },
    "Revero GT (21-inch wheels)": {
      2020: "2020",
    },
  },
  Kia: {
    Optima: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Rio: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Sedona: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Sportage 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sportage 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Soul: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Forte: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Sorento 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Sorento 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Optima Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Sportage FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sportage AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Cadenza: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Forte Koup": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Sorento FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Spectra: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2003: "2003",
      2002: "2002",
    },
    "Sorento AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Rondo: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Amanti: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    K900: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Forte 5": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Rio Eco": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Soul Electric": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Optima FE": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Niro: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Niro Touring": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Sedona SXL": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Stinger AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Forte Eco": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Optima Hybrid EX": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Stinger RWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Niro FE": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Sedona SX": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Optima Plug-in Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Sorento FE AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Sportage FE AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Borrego 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Borrego 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Sportage FE FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Soul ECO dynamics": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Niro Plug-in Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Forte FE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Niro Electric": {
      2020: "2020",
      2019: "2019",
    },
    "Sportage SX AWD": {
      2016: "2016",
      2015: "2015",
    },
    "Soul Eco": {
      2013: "2013",
      2012: "2012",
    },
    "Telluride AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Sephia/Spectra": {
      2001: "2001",
      2000: "2000",
    },
    "Telluride FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Soul Eco dynamics": {
      2021: "2021",
      2020: "2020",
    },
    "Sportage SX FWD": {
      2016: "2016",
      2015: "2015",
    },
    "Optima (2006 New Model)": {
      2006: "2006",
    },
    "Spectra 1.8L": {
      2004: "2004",
    },
    "Spectra 2.0L": {
      2004: "2004",
    },
    "Optima S": {
      2018: "2018",
    },
    "Seltos FWD": {
      2021: "2021",
    },
    "Seltos AWD": {
      2021: "2021",
    },
    K5: {
      2021: "2021",
    },
    "K5 AWD": {
      2021: "2021",
    },
    "Sorento Hybrid": {
      2021: "2021",
    },
  },
  Koenigsegg: {
    "Agera RS": {
      2018: "2018",
    },
  },
  Lamborghini: {
    "Aventador Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Gallardo Coupe": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Gallardo Spyder": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Aventador Roadster": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    Huracan: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Huracan Spyder": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Huracan 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Huracan Spyder 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "L-147/148 Murcielago": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Urus: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Murcielago Roadster": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    Murcielago: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "L-140/141 Gallardo": {
      2005: "2005",
      2004: "2004",
    },
    "L-147 Murcielago": {
      2003: "2003",
      2002: "2002",
    },
    "Veneno Roadster": {
      2015: "2015",
    },
    "Aventador S Coupe": {
      2018: "2018",
    },
    "DB132/144 Diablo": {
      2001: "2001",
    },
    "Murcielago Reventon": {
      2008: "2008",
    },
    "Aventador Veneno Coupe": {
      2014: "2014",
    },
    "Murcielago Reventon Roadster": {
      2010: "2010",
    },
    "Gallardo Coupe SL": {
      2007: "2007",
    },
    "L-140/715 Gallardo": {
      2006: "2006",
    },
    "Aventador Coupe LP740-4": {
      2017: "2017",
    },
    "Aventador Roadster LP740-4": {
      2017: "2017",
    },
    "Aventador S Roadster": {
      2018: "2018",
    },
    "Aventador Sian Roadster": {
      2020: "2020",
    },
    "Aventador Sian Coupe": {
      2020: "2020",
    },
  },
  "Land Rover": {
    "Range Rover": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Range Rover Sport": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    LR2: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Range Rover LWB": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    LR4: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Range Rover Evoque": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Discovery Sport": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    LR3: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Discovery Series II": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Range Rover Velar": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Range Rover LWB SVA": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Range Rover Sport SVR": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Range Rover SVA": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    Discovery: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Range Rover PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Range Rover Evoque Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Freelander: {
      2005: "2005",
      2004: "2004",
      2002: "2002",
    },
    "Range Rover Sport PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Defender 90 MHEV": {
      2021: "2021",
      2020: "2020",
    },
    "Range Rover MHEV": {
      2021: "2021",
      2020: "2020",
    },
    "Range Rover L FFV": {
      2015: "2015",
      2014: "2014",
    },
    "Range Rover Sport FFV": {
      2015: "2015",
      2014: "2014",
    },
    "Defender 110 MHEV": {
      2021: "2021",
      2020: "2020",
    },
    "Range Rover FFV": {
      2015: "2015",
      2014: "2014",
    },
    "Range Rover Sport MHEV": {
      2021: "2021",
      2019: "2019",
    },
    "Range Rover Sport TDV6": {
      2016: "2016",
    },
    "Range Rover Evoque Cabriolet": {
      2019: "2019",
    },
    "Range Rover Evoque 286HP": {
      2019: "2019",
    },
    "Discovery Sport Si4": {
      2019: "2019",
    },
    "Freelander 3 Door": {
      2003: "2003",
    },
    "Evoque MHEV": {
      2020: "2020",
    },
    Evoque: {
      2020: "2020",
    },
    "Range Rover SV Coupe": {
      2019: "2019",
    },
    "Range Rover Evoque (286 Hp)": {
      2018: "2018",
    },
    "Range Rover Velar P380": {
      2020: "2020",
    },
    "Range Rover TDV6": {
      2016: "2016",
    },
    "Discovery Sport MHEV": {
      2020: "2020",
    },
    "Defender 90": {
      2020: "2020",
    },
    "Discovery Sport (286 Hp)": {
      2018: "2018",
    },
    "Range Rover Evoque 237HP": {
      2019: "2019",
    },
    "Range Rover Velar P300": {
      2020: "2020",
    },
    "Defender 110": {
      2020: "2020",
    },
    "Freelander 5 Door": {
      2003: "2003",
    },
  },
  Lexus: {
    "ES 350": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "LX 570": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "GS 350": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "GS 350 AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "GX 460": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "RX 350 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "RX 450h AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "IS 350": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "LS 460 L": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "IS 350 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "LS 460": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "GS 450h": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "RX 350": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "IS 250 AWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "LS 460 L AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "LS 600h L": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "LS 460 AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "ES 300h": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "IS 300": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "LX 470": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CT 200h": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "RC F": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "IS F": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "RC 350": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "GX 470": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "NX 300h AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "RC 350 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "RX 450h": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "SC 430": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "IS 250": {
      2015: "2015",
      2014: "2014",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "IS 300 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "LS 430": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "GS 300/GS 430": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "RC 300 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "GS F": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "GS 350 F Sport": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "RX 350 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "IS 250/IS 250C": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "NX 300": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "NX 300 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 300": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "RX 350 L": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "LC 500": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "ES 300": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "GS 460": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "LC 500h": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 450h L AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RC 300": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 300 4WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "NX 300 AWD F Sport": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 350 L AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "IS 350/IS 350C": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "NX 200t AWD F Sport": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "UX 250h AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "RX 330 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "LS 500": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "LS 500h": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 350 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "NX 200t AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "ES 350 F Sport": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "NX 200t": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "ES 330": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "LS 500h AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RX 400h 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "RX 400h 4WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "RX 330 4WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "HS 250h": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "UX 250h": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "LS 500 AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "IS 250 C": {
      2015: "2015",
      2014: "2014",
    },
    "GS 200t F Sport": {
      2017: "2017",
      2016: "2016",
    },
    "IS 200t": {
      2017: "2017",
      2016: "2016",
    },
    "IS 350 C": {
      2015: "2015",
      2014: "2014",
    },
    "UX 200": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "GS 300 F Sport": {
      2019: "2019",
      2018: "2018",
    },
    "RC 200t": {
      2017: "2017",
      2016: "2016",
    },
    "SC 300/SC 430": {
      2003: "2003",
      2002: "2002",
    },
    "NX 300h": {
      2016: "2016",
      2015: "2015",
    },
    "GS 200t": {
      2017: "2017",
      2016: "2016",
    },
    "GS 300": {
      2019: "2019",
      2018: "2018",
    },
    "GS 300 4WD": {
      2006: "2006",
    },
    "GS 430": {
      2007: "2007",
    },
    SC: {
      2000: "2000",
    },
    LFA: {
      2012: "2012",
    },
    "LS 400": {
      2000: "2000",
    },
    "GS 300/GS 400": {
      2000: "2000",
    },
    "LC 500 Convertible": {
      2021: "2021",
    },
    "ES 250 AWD": {
      2021: "2021",
    },
  },
  Lincoln: {
    "Navigator 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "MKZ AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "MKZ FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "MKX FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "MKX AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "MKT FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "MKT AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "MKZ Hybrid FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Navigator 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Town Car": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "MKS FWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "MKS AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    LS: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "MKT Livery AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Navigator 2WD FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Navigator L 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "MKC FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "MKC AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Continental  AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "MKT Hearse AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2011: "2011",
    },
    "MKT Livery FWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Navigator 4WD FFV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Continental  FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Mark LT": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Navigator L 4WD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Continental: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "MKT Limo AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Town Car FFV": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Aviator 4WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Aviator 2WD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Aviator RWD": {
      2021: "2021",
      2020: "2020",
    },
    "Blackwood 2WD": {
      2003: "2003",
      2002: "2002",
    },
    "Continental Coach": {
      2020: "2020",
      2019: "2019",
    },
    "Corsair FWD": {
      2021: "2021",
      2020: "2020",
    },
    "Nautilus AWD": {
      2020: "2020",
      2019: "2019",
    },
    "Aviator AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Aviator PHEV AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Nautilus FWD": {
      2020: "2020",
      2019: "2019",
    },
    "Corsair AWD": {
      2021: "2021",
      2020: "2020",
    },
    Zephyr: {
      2006: "2006",
    },
    "Mark LT 4WD": {
      2008: "2008",
    },
    "Navigator Limo. 2WD FFV": {
      2011: "2011",
    },
  },
  "London Taxi": {
    "London Taxi": {
      2003: "2003",
    },
  },
  Lotus: {
    Evora: {
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Elise/Exige": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Esprit V8": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Elise/exige": {
      2005: "2005",
    },
    "Evora GT": {
      2020: "2020",
    },
  },
  Mahindra: {
    TR40: {
      2011: "2011",
    },
  },
  Maserati: {
    GranTurismo: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "GranTurismo Convertible": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Quattroporte: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
    },
    "Quattroporte SQ4 V6": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Quattroporte S": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Quattroporte GTS": {
      2020: "2020",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Levante S": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    Levante: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Ghibli V6": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Ghibli  S RWD": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
    },
    "Ghibli V6 SQ4 AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Levante GTS": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Quattroporte SQ4": {
      2021: "2021",
      2020: "2020",
    },
    "Ghibli S": {
      2021: "2021",
      2020: "2020",
    },
    "Ghibli SQ4": {
      2021: "2021",
      2020: "2020",
    },
    "Quattroporte V8": {
      2019: "2019",
      2018: "2018",
    },
    "Ghibli V6 SQ4": {
      2016: "2016",
      2015: "2015",
    },
    "Levante Trofeo": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Spider Cambiocorsa/Spider GT": {
      2004: "2004",
      2003: "2003",
    },
    "Ghibli  V6 RWD": {
      2019: "2019",
      2018: "2018",
    },
    "Coupe Cambiocorsa/Coupe GT": {
      2004: "2004",
      2003: "2003",
    },
    Ghibli: {
      2021: "2021",
      2020: "2020",
    },
    "Ghibli V6 AWD": {
      2014: "2014",
    },
    "Ghibli S RWD": {
      2017: "2017",
    },
    "Coupe and Gransport": {
      2005: "2005",
    },
    "Coupe Cambiocorsa/GT/G-Sport": {
      2006: "2006",
    },
    "Spyder Cambiocorsa/GT/90 ANV": {
      2005: "2005",
    },
    "Coupe Cambiocorsa/coupe GT": {
      2002: "2002",
    },
    "Spider Cambiocorsa/spider GT": {
      2002: "2002",
    },
    "Quattroporte/QP Sport GT": {
      2007: "2007",
    },
    "Ghibli Trofeo": {
      2021: "2021",
    },
    "Quattroporte Trofeo": {
      2021: "2021",
    },
  },
  Maybach: {
    57: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    62: {
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "57S": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "62S": {
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "57 Zeppelin": {
      2010: "2010",
    },
    Landaulet: {
      2012: "2012",
    },
  },
  Mazda: {
    6: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "CX-9 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "CX-9 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "MX-5": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    3: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "B4000 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tribute 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    2: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "CX-5 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    5: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "B4000 2WD": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "B2300 2WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "CX-5 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "RX-8": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    MPV: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "MX-5 Miata": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CX-7 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "CX-7 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Speed 3": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "CX-3 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "CX-3 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Tribute 2WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "3 5-Door": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    B3000: {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2001: "2001",
    },
    "Tribute Hybrid 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
    },
    "3 4-Door": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Tribute Hybrid 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "6 Sport Wagon": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Tribute FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    Millenia: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "B3000 4WD": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    626: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "B3000 2WD": {
      2008: "2008",
      2003: "2003",
      2002: "2002",
    },
    "3 4-Door 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "3 4-Door 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "3 5-Door 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "3 5-Door 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Protege/Protege 5": {
      2003: "2003",
      2002: "2002",
    },
    "CX-30 4WD": {
      2021: "2021",
      2020: "2020",
    },
    "CX-30 2WD": {
      2021: "2021",
      2020: "2020",
    },
    "Tribute FWD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "3 DI 5-Door": {
      2013: "2013",
      2012: "2012",
    },
    "3 DI 4-Door": {
      2013: "2013",
      2012: "2012",
    },
    "B3000 FFV 2WD": {
      2002: "2002",
      2000: "2000",
    },
    "Tribute 4WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    Protege: {
      2000: "2000",
    },
    "B2500 2WD": {
      2001: "2001",
    },
    "B3000 (FFV) Ethanol 2WD": {
      2001: "2001",
    },
    "2500 2WD": {
      2000: "2000",
    },
    "Protege/Protege MPS": {
      2001: "2001",
    },
    "B3000 FFV 4WD": {
      2000: "2000",
    },
    "B3000 2WD FFV": {
      2003: "2003",
    },
  },
  "McLaren Automotive": {
    "570S Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "720S Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "570GT": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "570S Spider": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "650S Coupe": {
      2016: "2016",
      2015: "2015",
    },
    "650S Spider": {
      2016: "2016",
      2015: "2015",
    },
    "MP4-12C Coupe": {
      2014: "2014",
      2012: "2012",
    },
    P1: {
      2015: "2015",
      2014: "2014",
    },
    "720S Spider": {
      2020: "2020",
      2019: "2019",
    },
    "MP4-12C Spider": {
      2014: "2014",
    },
    "620R Coupe": {
      2020: "2020",
    },
    "540C Coupe": {
      2018: "2018",
    },
    "675LT Spider": {
      2016: "2016",
    },
    GT: {
      2020: "2020",
    },
    "650S Can-Am": {
      2016: "2016",
    },
    "MSO HS": {
      2016: "2016",
    },
    "600LT Spider": {
      2020: "2020",
    },
    "675LT Coupe": {
      2016: "2016",
    },
    Speedtail: {
      2020: "2020",
    },
    "600LT Coupe": {
      2019: "2019",
    },
    Senna: {
      2019: "2019",
    },
  },
  "Mercedes-Benz": {
    S600: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    CL600: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "C300 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    SL550: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "E350 4matic": {
      2021: "2021",
      2020: "2020",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    E350: {
      2021: "2021",
      2020: "2020",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    SLK350: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    CLS550: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    S550: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "S550 4matic": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    G550: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "E350 4matic (wagon)": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    ML350: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    C300: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "SLK55 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "GL450 4matic": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "GL550 4matic": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "E550 4matic": {
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "ML350 4matic": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "SL65 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "G55 AMG": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "ML63 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    SL600: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CL65 AMG": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
    },
    C350: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "S65 AMG": {
      2015: "2015",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "CLA250 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    CLA250: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "E550 Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "CLS63 AMG": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "E63 AMG": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "E55 AMG": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    CL500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "GLA250 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "CLS550 4matic": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "E550 Convertible": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "C63 AMG": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    G500: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    S500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    GLA250: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    SL500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CL63 AMG": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "ML550 4matic": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "R350 4matic": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    S430: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    E320: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    GLK350: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "E350 Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    GLC300: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "CL550 4matic": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "E320 (Wagon)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "GLK350 4matic": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "SL55 AMG": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    CLK320: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "AMG C63 S": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "AMG C63": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "GLC300 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "S63 AMG": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "CLK320 (Cabriolet)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "CL55 AMG": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "S55 AMG": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "SL63 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
    },
    C320: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "GLC300 4matic Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Metris (Passenger Van)": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    ML500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "C300 4matic Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    SLR: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "C230 Kompressor": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2000: "2000",
    },
    "GLE350 4matic": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "CLK55 AMG": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "C300 Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "E63 AMG (wagon)": {
      2013: "2013",
      2012: "2012",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Metris (Cargo Van)": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG S65": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "GLS450 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "C300 4matic Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG CLA45 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "E350 Convertible": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    GLE350: {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG G63": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    E550: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "C300 Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "C63 AMG Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "AMG C63 S Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "CLK55 AMG (Cabriolet)": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2002: "2002",
    },
    "AMG SLC43": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG GLE63": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "E320 4Matic (Wagon)": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "AMG S63 4matic": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    SL450: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    E500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "S500 4matic": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    CLK500: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "AMG C43 4matic Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    SLC300: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "E400 Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    SLK250: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "C350 Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "AMG C63 Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    SLK320: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "CLK430 (Cabriolet)": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "AMG GLE63 S": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG SL63": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG C63 S Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG GLC43 4matic Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    SLK300: {
      2016: "2016",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    C240: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "AMG GLA45 4matic": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG C43 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "CLK350 (Cabriolet)": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "S400 Hybrid": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "S430 4matic": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "C350 4matic Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "C250 Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    CLK350: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "AMG S63 4matic Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "E400 Coupe": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    ML320: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "AMG C43 4matic Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG C63 Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG GLC43 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "SLK230 Kompressor": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "E400 4matic Coupe": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "AMG GLE63 S Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    E300: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    S450: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "AMG G65": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "SLS AMG GT Roadster": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "E350 4matic Coupe": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Metris (Cargo Van, LWB)": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "SLK32 AMG": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    S560: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "E450 4matic Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG GT C Roadster": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "GL63 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "AMG GLE43 4matic Coupe": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "S560 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    CLS450: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "E320 4Matic": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "E430 4Matic": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "C240 4matic (Wagon)": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "E400 4matic": {
      2018: "2018",
      2016: "2016",
      2015: "2015",
    },
    "AMG E53 4matic Plus Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG CLS53 4matic Plus": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG GT 53 4matic Plus": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Maybach S650": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "E450 Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    C350e: {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "C240 4matic": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "S560 4matic Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "ML55 AMG": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "SLS AMG Roadster": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "AMG SL65": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "S550 4matic Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    C250: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "AMG GLS63": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG E63 S 4matic (wagon)": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
    },
    A220: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "GLE550e 4matic": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "AMG GT Roadster": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "GLK250 Bluetec 4matic": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "S560 Convertible": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    S550e: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "G63 AMG": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "E400 Hybrid": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "C32 AMG": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "E400 4matic (station wagon)": {
      2018: "2018",
      2017: "2017",
      2015: "2015",
    },
    "AMG S63 4matic Convertible": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG GT C Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "C320 Sports Coupe": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "SLS AMG GT Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "A220 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG GT R Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "AMG E63 S 4matic": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
    },
    "E450 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG GLE43 4matic": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG S65 Coupe": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
    },
    "CLK550 (Cabriolet)": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "CLS450 4matic": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "E500 4matic": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "GLC350e 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    C280: {
      2007: "2007",
      2006: "2006",
      2000: "2000",
    },
    "AMG E53 4matic Plus Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG CLS63 S 4matic": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "E450 4matic Coupe": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    CLK430: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "E300 4matic": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "GLE400 4matic": {
      2019: "2019",
      2017: "2017",
      2016: "2016",
    },
    "S450 4matic": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    E430: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    SLK280: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "E320 Bluetec": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "E450 Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "GLS550 4matic": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "AMG GT Coupe": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    CLK550: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "CLK500 (Cabriolet)": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    E400: {
      2016: "2016",
      2015: "2015",
    },
    "E500 4matic (Wagon)": {
      2005: "2005",
      2004: "2004",
    },
    "C320 4matic (Wagon)": {
      2004: "2004",
      2003: "2003",
    },
    "C280 4matic": {
      2007: "2007",
      2006: "2006",
    },
    "C240 FFV (Wagon)": {
      2005: "2005",
      2004: "2004",
    },
    "C320 4matic": {
      2005: "2005",
      2003: "2003",
    },
    "AMG GT S": {
      2017: "2017",
      2016: "2016",
    },
    CL550: {
      2008: "2008",
      2007: "2007",
    },
    R350: {
      2008: "2008",
      2006: "2006",
    },
    ML430: {
      2001: "2001",
      2000: "2000",
    },
    "C320 FFV": {
      2005: "2005",
      2004: "2004",
    },
    "AMG GLC63 S 4matic Coupe": {
      2019: "2019",
      2018: "2018",
    },
    S560e: {
      2020: "2020",
      2019: "2019",
    },
    "ML320 CDI 4matic": {
      2008: "2008",
      2007: "2007",
    },
    "E320 Cdi": {
      2006: "2006",
      2005: "2005",
    },
    "SLS AMG": {
      2012: "2012",
      2011: "2011",
    },
    "E63 AMG S 4matic": {
      2015: "2015",
      2014: "2014",
    },
    "E63 AMG 4matic": {
      2015: "2015",
      2014: "2014",
    },
    "C63 AMG Black Series Coupe": {
      2013: "2013",
      2012: "2012",
    },
    "CLA45 AMG 4matic": {
      2015: "2015",
      2014: "2014",
    },
    SL400: {
      2016: "2016",
      2015: "2015",
    },
    "AMG GT 63 S 4matic Plus": {
      2020: "2020",
      2019: "2019",
    },
    "AMG GLC63 4matic": {
      2019: "2019",
      2018: "2018",
    },
    "E320 4matic": {
      2005: "2005",
      2004: "2004",
    },
    "GLB250 4matic": {
      2021: "2021",
      2020: "2020",
    },
    "C240 (Wagon)": {
      2004: "2004",
      2003: "2003",
    },
    CLS400: {
      2016: "2016",
      2015: "2015",
    },
    "S63 AMG 4matic": {
      2015: "2015",
      2014: "2014",
    },
    "AMG S65 Convertible": {
      2019: "2019",
      2018: "2018",
    },
    "CLS63 AMG S 4matic": {
      2015: "2015",
      2014: "2014",
    },
    "C240 FFV": {
      2005: "2005",
      2004: "2004",
    },
    "R320 CDI 4matic": {
      2008: "2008",
      2007: "2007",
    },
    "C230 Kompressor Sports Coupe": {
      2005: "2005",
      2004: "2004",
    },
    "AMG A35 4matic": {
      2021: "2021",
      2020: "2020",
    },
    "C350 4matic": {
      2007: "2007",
      2006: "2006",
    },
    "C320 (Wagon)": {
      2003: "2003",
      2002: "2002",
    },
    C230: {
      2007: "2007",
      2006: "2006",
    },
    "CLK63 AMG (Cabriolet)": {
      2008: "2008",
      2007: "2007",
    },
    "C55 AMG": {
      2006: "2006",
      2005: "2005",
    },
    "AMG GT 63 4matic Plus": {
      2020: "2020",
      2019: "2019",
    },
    "CLS400 4matic": {
      2016: "2016",
      2015: "2015",
    },
    "E63 AMG S 4matic (wagon)": {
      2015: "2015",
      2014: "2014",
    },
    "AMG GLC63 4matic Coupe": {
      2019: "2019",
      2018: "2018",
    },
    GLB250: {
      2021: "2021",
      2020: "2020",
    },
    "AMG E43 4matic": {
      2018: "2018",
      2017: "2017",
    },
    "AMG E53 4matic Plus": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "AMG CLA35 4matic": {
      2021: "2021",
      2020: "2020",
    },
    "E320 4matic (Wagon)": {
      2005: "2005",
      2004: "2004",
    },
    "Maybach S560 4matic": {
      2019: "2019",
      2018: "2018",
    },
    "GLE450 4matic": {
      2021: "2021",
      2020: "2020",
    },
    "GL320 CDI 4matic": {
      2008: "2008",
      2007: "2007",
    },
    "C320 Sports Coupe FFV": {
      2005: "2005",
      2004: "2004",
    },
    "B-Class Electric Drive": {
      2015: "2015",
      2014: "2014",
    },
    B250e: {
      2017: "2017",
      2016: "2016",
    },
    "ML450 Hybrid 4matic": {
      2011: "2011",
      2010: "2010",
    },
    "SLS AMG Coupe": {
      2014: "2014",
      2013: "2013",
    },
    "E450 4matic (station wagon)": {
      2020: "2020",
      2019: "2019",
    },
    "S65 AMG Coupe": {
      2017: "2017",
      2015: "2015",
    },
    "AMG GT S Coupe": {
      2019: "2019",
      2018: "2018",
    },
    "C320 FFV (Wagon)": {
      2004: "2004",
    },
    "C450 AMG": {
      2016: "2016",
    },
    "ML500 4matic": {
      2007: "2007",
    },
    "G550 4x4": {
      2018: "2018",
    },
    "E500 (Wagon)": {
      2004: "2004",
    },
    "AMG S63 4matic Plus Coupe": {
      2020: "2020",
    },
    "Maybach S550 4matic": {
      2017: "2017",
    },
    "AMG GT": {
      2017: "2017",
    },
    "AMG GL63": {
      2016: "2016",
    },
    "ML250 Bluetec 4matic": {
      2015: "2015",
    },
    "GLE300 d 4matic": {
      2016: "2016",
    },
    "S63 AMG 4matic Coupe": {
      2015: "2015",
    },
    "R500 4matic": {
      2007: "2007",
    },
    "GLE580 4matic": {
      2020: "2020",
    },
    "C320 4matic Sedan": {
      2004: "2004",
    },
    "S550 Convertible": {
      2017: "2017",
    },
    "AMG GLA35 4matic": {
      2021: "2021",
    },
    R500: {
      2006: "2006",
    },
    "AMG GLE63 S 4matic Plus": {
      2021: "2021",
    },
    ML55: {
      2000: "2000",
    },
    "E63 AMG 4matic (wagon)": {
      2014: "2014",
    },
    "AMG GLC63 4matic Plus Coupe": {
      2020: "2020",
    },
    SLK230: {
      2004: "2004",
    },
    S350: {
      2006: "2006",
    },
    "R63 AMG": {
      2007: "2007",
    },
    "AMG GLE53 4matic Plus Coupe": {
      2021: "2021",
    },
    "CLS63 AMG 4matic": {
      2014: "2014",
    },
    "E55 AMG (Wagon)": {
      2005: "2005",
    },
    "AMG E63 4matic": {
      2016: "2016",
    },
    "Maybach S 600": {
      2016: "2016",
    },
    "E400 4matic Convertible": {
      2018: "2018",
    },
    "Maybach S650 Convertible": {
      2017: "2017",
    },
    "C43 AMG": {
      2000: "2000",
    },
    "E450 4matic All-Terrain (wagon)": {
      2021: "2021",
    },
    "G550 4x4 (Special Off-Road Model)": {
      2017: "2017",
    },
    "E500 4matic (wagon)": {
      2006: "2006",
    },
    "AMG E63 S 4matic Plus (wagon)": {
      2020: "2020",
    },
    "AMG GLC63 4matic Plus": {
      2020: "2020",
    },
    "AMG GT R Roadster": {
      2020: "2020",
    },
    "AMG GLC63 S 4matic Plus Coupe": {
      2020: "2020",
    },
    "Maybach S600": {
      2017: "2017",
    },
    "CLK63 AMG": {
      2008: "2008",
    },
    "E55 AMG (wagon)": {
      2006: "2006",
    },
    "AMG S63 4matic Plus": {
      2020: "2020",
    },
    "E63 AMG S": {
      2014: "2014",
    },
    "ML400 4matic": {
      2015: "2015",
    },
    "E350 4Matic": {
      2013: "2013",
    },
    "SLS AMG Black Series Coupe": {
      2014: "2014",
    },
    "C400 4matic": {
      2015: "2015",
    },
    CLS500: {
      2006: "2006",
    },
    "AMG GLS63 4matic Plus": {
      2021: "2021",
    },
    "CLS63 AMG S": {
      2014: "2014",
    },
    "AMG GLE53 4matic Plus": {
      2021: "2021",
    },
    "CLS55 AMG": {
      2006: "2006",
    },
    "GLS580 4matic": {
      2020: "2020",
    },
    "AMG S63 4matic Plus Convertible": {
      2020: "2020",
    },
    "GLE450 AMG Coupe": {
      2016: "2016",
    },
    "GLE350 d 4matic": {
      2016: "2016",
    },
    "E350 (wagon)": {
      2006: "2006",
    },
    "S560 4matic Maybach": {
      2020: "2020",
    },
    "S65 AMG Convertible": {
      2017: "2017",
    },
    "AMG E63 S 4matic Plus": {
      2020: "2020",
    },
    "GLA45 AMG 4matic": {
      2015: "2015",
    },
    "AMG SLK55": {
      2016: "2016",
    },
    "AMG GT 43 4matic Plus": {
      2021: "2021",
    },
  },
  Mercury: {
    "Mountaineer 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Mountaineer 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Grand Marquis": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sable Wagon": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Sable: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Mariner 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Mariner FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Mariner Hybrid 4WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    Milan: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Grand Marquis FFV": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Monterey Wagon FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Mariner Hybrid FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Mountaineer 2WD FFV": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Montego AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Milan AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Cougar: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Mountaineer 4WD FFV": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Villager FWD Wagon": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Montego FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    Marauder: {
      2004: "2004",
      2003: "2003",
    },
    "Mariner 4WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Mariner FWD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Milan AWD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Milan Hybrid FWD": {
      2011: "2011",
      2010: "2010",
    },
    "Sable AWD": {
      2009: "2009",
      2008: "2008",
    },
    "Sable FWD": {
      2009: "2009",
      2008: "2008",
    },
    "Mountaineer FFV 2WD": {
      2005: "2005",
    },
    "Milan FWD": {
      2011: "2011",
    },
    "Mountaineer AWD": {
      2010: "2010",
    },
    "Mariner 2WD": {
      2005: "2005",
    },
    "Sable Wagon (FFV)": {
      2001: "2001",
    },
    Mystique: {
      2000: "2000",
    },
    "Milan FFV": {
      2010: "2010",
    },
    "Sable (FFV)": {
      2001: "2001",
    },
    "Milan S": {
      2010: "2010",
    },
    "Mountaineer FFV 4WD": {
      2005: "2005",
    },
    "Milan S FWD": {
      2011: "2011",
    },
    "Milan FWD FFV": {
      2011: "2011",
    },
  },
  MINI: {
    "Cooper Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Cooper S Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "John Cooper Works Convertible": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    Cooper: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Cooper S": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Cooper Countryman": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Cooper S Countryman": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Cooper S Countryman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Cooper S Clubman": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "JCW Countryman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "John Cooper Works Hardtop": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Cooper Clubman": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cooper S Hardtop 4 door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "John Cooper Works Clubman": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Cooper Hardtop 2 door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Cooper Hardtop 4 door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Cooper S Hardtop 2 door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Cooper S Clubman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "John Cooper Works Clubman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Cooper Countryman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "John Cooper Works": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Cooper Roadster": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Clubman: {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "John Cooper Works Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cooper S Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cooper Coupe": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "John Cooper Works Roadster": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cooper S Roadster": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Cooper Paceman": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Cooper Clubman All4": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "JCW Paceman All4": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Cooper S Paceman All4": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Clubman S": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Cooper S Paceman": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Cooper SE Countryman All4": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Cooper (3-doors)": {
      2015: "2015",
      2014: "2014",
    },
    "Cooper Clubvan": {
      2014: "2014",
      2013: "2013",
    },
    "Cooper S (3-doors)": {
      2015: "2015",
      2014: "2014",
    },
    "Cooper SE Hardtop 2 door": {
      2021: "2021",
      2020: "2020",
    },
    "John Cooper Works GP-2": {
      2013: "2013",
    },
    "Cooper S (5-doors)": {
      2015: "2015",
    },
    "JCW Countryman Coupe All4": {
      2013: "2013",
    },
    "Cooper S Countryman Coupe": {
      2013: "2013",
    },
    "Cooper (5-doors)": {
      2015: "2015",
    },
    MiniE: {
      2008: "2008",
    },
    "Cooper S Countryman Coupe All4": {
      2013: "2013",
    },
    "Cooper S JCWorks GP Kit": {
      2006: "2006",
    },
    "Cooper Countryman Coupe": {
      2013: "2013",
    },
    "John Cooper Works GP": {
      2021: "2021",
    },
  },
  Mitsubishi: {
    "Outlander 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Outlander 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Lancer: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    Eclipse: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Galant: {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Lancer Evolution": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Eclipse Spyder": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Outlander Sport 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Outlander Sport 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Lancer Sportback": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Mirage: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Montero: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Endeavor 2WD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Raider Pickup 2WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Montero Sport 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "i-MiEV": {
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Montero Sport 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Mirage G4": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Lancer AWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Raider Pickup 4WD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Endeavor AWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    Diamante: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Endeavor 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Eclipse Cross ES 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Diamante Sedan": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Eclipse Cross 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Eclipse Cross 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Eclipse Cross ES 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Outlander PHEV": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Nativa 4WD (Puerto Rico Only)": {
      2002: "2002",
      2001: "2001",
    },
    "Lancer 4WD": {
      2017: "2017",
      2016: "2016",
    },
    "Nativa 2WD (Puerto Rico Only)": {
      2002: "2002",
      2001: "2001",
    },
    "Nativa 2WD(Puerto Rico Only)": {
      2004: "2004",
      2003: "2003",
    },
    "Nativa 2WD": {
      2000: "2000",
    },
    "Nativa 4WD": {
      2000: "2000",
    },
    Endeavor: {
      2004: "2004",
    },
  },
  "Mobility Ventures LLC": {
    "MV-1": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "MV-1 CNG": {
      2014: "2014",
    },
  },
  Morgan: {
    "Plus Eight": {
      2003: "2003",
      2002: "2002",
    },
  },
  Nissan: {
    Altima: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Pathfinder 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Sentra: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Pathfinder 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Frontier 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Maxima: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Murano AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Titan 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Titan 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Xterra 2WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Quest: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Armada 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Murano FWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Armada 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    Versa: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Frontier 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2000: "2000",
    },
    "Rogue FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Rogue AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "GT-R": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "370Z Roadster": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Xterra 4WD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "370Z": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "NV200 Cargo Van": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Frontier V6 4WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Frontier V6 2WD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Juke AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Leaf: {
      2018: "2018",
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    Juke: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "350z Roadster": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Cube: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Altima Coupe": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "350z": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Pathfinder 4WD Platinum": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Xterra V6 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Xterra V6 4WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Altima Hybrid": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "NV200 NYC Taxi": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Sentra FE": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2009: "2009",
    },
    "Murano CrossCabriolet": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Rogue Sport AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Altima SR": {
      2021: "2021",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Titan 4WD PRO4X": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Frontier 4WD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Rogue Hybrid  AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Altima AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Sentra Nismo": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Frontier 2WD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Juke Nismo RS AWD": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Altima AWD SR/Platinum": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    Kicks: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Juke Nismo RS": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Rogue Hybrid  FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Pathfinder FE 2WD": {
      2010: "2010",
      2009: "2009",
    },
    "Rogue Select FWD": {
      2015: "2015",
      2014: "2014",
    },
    "Titan 4WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Leaf (62 kW-hr battery pack)": {
      2020: "2020",
      2019: "2019",
    },
    "Titan 2WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Rogue Sport FWD": {
      2018: "2018",
      2017: "2017",
    },
    "Pathfinder Hybrid 4WD": {
      2015: "2015",
      2014: "2014",
    },
    "Rogue Select AWD": {
      2015: "2015",
      2014: "2014",
    },
    "Altima SR/Platinum": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Armada 4WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Leaf SV/SL (62 kW-hr battery pack)": {
      2020: "2020",
      2019: "2019",
    },
    "Armada 2WD FFV": {
      2011: "2011",
      2010: "2010",
    },
    "Murano 2WD": {
      2004: "2004",
      2003: "2003",
    },
    "Rogue Sport": {
      2020: "2020",
      2019: "2019",
    },
    "Pathfinder Hybrid 2WD": {
      2015: "2015",
      2014: "2014",
    },
    "Leaf (40 kW-hr battery pack)": {
      2020: "2020",
      2019: "2019",
    },
    "Titan 4WD PRO-4X": {
      2021: "2021",
    },
    "Rogue AWD  SV/SL/Platinum": {
      2021: "2021",
    },
    "Murano Hybrid FWD": {
      2016: "2016",
    },
    "Sentra SR": {
      2020: "2020",
    },
    "Leaf (24 kW-hr battery pack)": {
      2016: "2016",
    },
    "Altra EV": {
      2000: "2000",
    },
    "370z": {
      2009: "2009",
    },
    "Pathfinder Armada 2WD": {
      2004: "2004",
    },
    "Murano Hybrid AWD": {
      2016: "2016",
    },
    "Versa FE": {
      2009: "2009",
    },
    "Titan FE 2WD": {
      2009: "2009",
    },
    "Pathfinder Armada 4WD": {
      2004: "2004",
    },
    "Titan 4WD  PRO4X": {
      2017: "2017",
    },
    "Rogue FWD  SV/SL/Platinum": {
      2021: "2021",
    },
    "Leaf (30 kW-hr battery pack)": {
      2016: "2016",
    },
    "Titan FE 2WD FFV": {
      2009: "2009",
    },
    "Hyper-Mini": {
      2001: "2001",
    },
    "Altima SV/SL": {
      2021: "2021",
    },
  },
  Oldsmobile: {
    Alero: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Silhouette 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Bravada AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Intrigue: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Aurora: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Silhouette AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Bravada 2WD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
  },
  Pagani: {
    "Huayra Coupe": {
      2016: "2016",
      2014: "2014",
    },
    Huayra: {
      2018: "2018",
    },
    "Huayra Roadster BC": {
      2020: "2020",
    },
  },
  Plymouth: {
    Prowler: {
      2001: "2001",
      2000: "2000",
    },
    Neon: {
      2001: "2001",
      2000: "2000",
    },
    Breeze: {
      2000: "2000",
    },
    "Voyager/Grand Voyager 2WD": {
      2000: "2000",
    },
  },
  Polestar: {
    1: {
      2021: "2021",
      2020: "2020",
    },
    2: {
      2021: "2021",
    },
  },
  Pontiac: {
    "Grand Prix": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Vibe: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Bonneville: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Grand Am": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Sunfire: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    G6: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Vibe AWD": {
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Solstice: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Aztek FWD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Montana FWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Aztek AWD": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Torrent FWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Torrent AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    GTO: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Montana AWD": {
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "G5 XFE": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    G5: {
      2010: "2010",
      2009: "2009",
    },
    "G5 GT": {
      2010: "2010",
      2009: "2009",
    },
    "G6 GT/GTP Convertible": {
      2007: "2007",
      2006: "2006",
    },
    "Wave 5": {
      2006: "2006",
      2005: "2005",
    },
    "Firebird/Trans Am": {
      2001: "2001",
      2000: "2000",
    },
    G8: {
      2009: "2009",
      2008: "2008",
    },
    "G5/Pursuit": {
      2008: "2008",
      2007: "2007",
    },
    "Montana SV6 FWD": {
      2006: "2006",
    },
    "Montana SV6 AWD": {
      2006: "2006",
    },
    Wave: {
      2005: "2005",
    },
    "Montana SVX AWD": {
      2005: "2005",
    },
    "Montana SVX FWD": {
      2005: "2005",
    },
    "Monterey Wagon FWD": {
      2005: "2005",
    },
    Firebird: {
      2002: "2002",
    },
    "G3 Wave 5": {
      2009: "2009",
    },
    "G3 Wave": {
      2009: "2009",
    },
    "G3 (5-Door)": {
      2010: "2010",
    },
    "G3 (3-Door)": {
      2010: "2010",
    },
  },
  Porsche: {
    "Boxster S": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Boxster: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Cayenne S": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Cayenne Turbo": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Cayenne: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Cayman S": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    Cayman: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "911 Carrera": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2002: "2002",
      2000: "2000",
    },
    "911 Carrera Cabriolet": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2002: "2002",
    },
    "911 Carrera 4 Cabriolet": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2002: "2002",
    },
    "911 Carrera 4S": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2002: "2002",
    },
    "911 Carrera S": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 Carrera 4": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 Carrera S Cabriolet": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 Carrera 4S Cabriolet": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 Turbo Cabriolet": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Panamera 4S": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Panamera Turbo": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 Turbo": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2009: "2009",
      2008: "2008",
      2002: "2002",
      2001: "2001",
    },
    Panamera: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Panamera 4": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "911 GT3": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2011: "2011",
      2010: "2010",
      2008: "2008",
      2007: "2007",
    },
    "911 Turbo S Cabriolet": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Cayenne GTS": {
      2021: "2021",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "911 Turbo S": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Panamera 4S Executive": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Carrera 2 Coupe": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Carrera 4 Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Panamera S": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Carrera 2 Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "911 Targa 4S": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "911 GT3 RS": {
      2019: "2019",
      2016: "2016",
      2011: "2011",
      2010: "2010",
      2008: "2008",
      2007: "2007",
    },
    "911 Targa 4": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Panamera Turbo Executive": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Carrera 4 S Coupe": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Panamera GTS": {
      2020: "2020",
      2019: "2019",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Macan Turbo": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Cayenne Turbo S": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2010: "2010",
      2009: "2009",
    },
    "Macan S": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Carrera 4 S Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "911 Carrera GTS Cabriolet": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "911 Carrera GTS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "911 Carrera 4 GTS Cabriolet": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Carrera 2 S Cabriolet": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Carrera 2 S Coupe": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "911 Carrera 4 GTS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Panamera Turbo S": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "911 Targa 4 GTS": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Macan GTS": {
      2021: "2021",
      2020: "2020",
      2018: "2018",
      2017: "2017",
    },
    Macan: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Cayenne S Hybrid": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Panamera 4 Executive": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Carrera 4 Coupe": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Boxster GTS": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
    },
    "Cayenne Diesel": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Cayman GTS": {
      2019: "2019",
      2018: "2018",
      2016: "2016",
      2015: "2015",
    },
    "Cayenne S e-Hybrid": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Panamera Turbo S e-Hybrid ST": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera 4 e-Hybrid Executive": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera Turbo S e-Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera 4S ST": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "911 Carrera 4S Targa": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "911 GT2": {
      2009: "2009",
      2008: "2008",
      2002: "2002",
    },
    "Carrera 2 Cabriolet Kit": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "911 Speedster": {
      2019: "2019",
      2012: "2012",
      2011: "2011",
    },
    "911 Turbo S Coupe": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Panamera Turbo ST": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera 4 e-Hybrid": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "911 Carrera 4 Targa": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Targa: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Carrera 2 Coupe Kit": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Panamera Turbo S e-Hybrid Executive": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera 4 ST": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Carrera 4 Targa": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "911 GT2 RS": {
      2019: "2019",
      2018: "2018",
      2011: "2011",
    },
    "Targa Kit": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Carrera 4 S Kit": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Panamera 4 e-Hybrid ST": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Panamera Turbo S Executive": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "911 Turbo Coupe": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Boxster Spyder": {
      2016: "2016",
      2012: "2012",
      2011: "2011",
    },
    "Panamera S E-Hybrid": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Carrera 4 Cabriolet Kit": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Carrera 4 S Targa": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Boxster T": {
      2021: "2021",
      2020: "2020",
    },
    "Cayenne Platinum": {
      2018: "2018",
      2017: "2017",
    },
    "Carrera 2 911 GT3": {
      2005: "2005",
      2004: "2004",
    },
    "Turbo 4 911 Cab": {
      2005: "2005",
      2004: "2004",
    },
    "Cayman T": {
      2021: "2021",
      2020: "2020",
    },
    "Turbo 4 911 Turbo": {
      2007: "2007",
      2005: "2005",
    },
    "Cayenne S Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "Carrera GT": {
      2005: "2005",
      2004: "2004",
    },
    "Carrera 4 S Cabriolet Kit": {
      2005: "2005",
      2004: "2004",
    },
    "Macan Turbo Kit": {
      2018: "2018",
      2017: "2017",
    },
    "Cayenne e-Hybrid": {
      2020: "2020",
      2019: "2019",
    },
    "Turbo 4 911 Kit": {
      2005: "2005",
      2004: "2004",
    },
    "911 GTS": {
      2012: "2012",
      2011: "2011",
    },
    "911 GT3 Touring": {
      2019: "2019",
      2018: "2018",
    },
    "911 Carrera T": {
      2019: "2019",
      2018: "2018",
    },
    "Panamera S Hybrid": {
      2013: "2013",
      2012: "2012",
    },
    "911 GTS Cabriolet": {
      2012: "2012",
      2011: "2011",
    },
    "Panamera GTS ST": {
      2020: "2020",
      2019: "2019",
    },
    "Turbo 4 911 Cab Kit": {
      2005: "2005",
      2004: "2004",
    },
    "Cayenne Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "911 Targa": {
      2002: "2002",
    },
    "Panamera Turbo Exec": {
      2020: "2020",
    },
    "Cayman GT4": {
      2016: "2016",
    },
    "Turbo 2 911 Gt2": {
      2004: "2004",
    },
    "New 911 Carrera S": {
      2012: "2012",
    },
    "911 R": {
      2016: "2016",
    },
    "New 911 Carrera": {
      2012: "2012",
    },
    "Cayenne e-Hybrid Coupe": {
      2020: "2020",
    },
    "Cayenne Turbo Coupe": {
      2021: "2021",
      2020: "2020",
    },
    "718 Spyder": {
      2020: "2020",
    },
    "918 Spyder": {
      2015: "2015",
    },
    "911 Turbo S Exclusive": {
      2018: "2018",
    },
    "Turbo GT2": {
      2003: "2003",
    },
    "New 911 Carrera Cabriolet": {
      2012: "2012",
    },
    "Carrera 4 S": {
      2003: "2003",
    },
    "New 911 Carrera S Cabriolet": {
      2012: "2012",
    },
    "Turbo Kit": {
      2003: "2003",
    },
    "Taycan Turbo S": {
      2020: "2020",
    },
    "911 Carrera 2/4": {
      2001: "2001",
    },
    "Turbo 4 911 Turbo Cab S": {
      2005: "2005",
    },
    "Taycan 4S Perf Battery Plus": {
      2020: "2020",
    },
    "Cayenne Turbo Kit": {
      2010: "2010",
    },
    "Turbo 4 911 S": {
      2005: "2005",
    },
    "Cayman R": {
      2012: "2012",
    },
    "Turbo 2 911 GT2": {
      2005: "2005",
    },
    "911 Turbo S Exclusive Cabriolet": {
      2019: "2019",
    },
    "Cayenne Turbo S e-Hybrid": {
      2020: "2020",
    },
    "Turbo 4 911": {
      2004: "2004",
    },
    "Taycan Turbo": {
      2020: "2020",
    },
    "911 C4 GTS Cabriolet": {
      2012: "2012",
    },
    "718 Cayman GT4": {
      2020: "2020",
    },
    "911 C4 GTS": {
      2012: "2012",
    },
    Turbo: {
      2003: "2003",
    },
    "Cayenne Turbo S e-Hybrid Coupe": {
      2020: "2020",
    },
    "Cayenne TransSiberia": {
      2010: "2010",
    },
    "Cayenne GTS Coupe": {
      2021: "2021",
    },
  },
  Qvale: {
    "Detomaso Mangusta": {
      2000: "2000",
    },
  },
  Ram: {
    "1500 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "1500 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Promaster City": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "1500 HFE 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "C/V": {
      2014: "2014",
      2013: "2013",
    },
    "1500 Classic 2WD": {
      2020: "2020",
      2019: "2019",
    },
    "1500 Classic 4WD": {
      2020: "2020",
      2019: "2019",
    },
    "C/V Cargo Van": {
      2012: "2012",
    },
    "C/V Tradesman": {
      2015: "2015",
    },
    "1500 TRX 4WD": {
      2021: "2021",
    },
  },
  "Rolls-Royce": {
    Phantom: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Phantom EWB": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Ghost: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Phantom Drophead Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Phantom Coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Ghost EWB": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Wraith: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Dawn: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Cullinan: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    Corniche: {
      2001: "2001",
      2000: "2000",
    },
    "Silver Seraph": {
      2002: "2002",
      2001: "2001",
    },
    "Park Ward": {
      2002: "2002",
      2001: "2001",
    },
    "Cullinan Black Badge": {
      2021: "2021",
      2020: "2020",
    },
    "Dawn Black Badge": {
      2021: "2021",
    },
    "Wraith Black Badge": {
      2021: "2021",
    },
  },
  "Roush Performance": {
    "Stage 3 Mustang": {
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "F150 Pickup 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
    },
    "F150 Pickup 4WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
    },
    "Stage 3 F150 Super Cab 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "Stage 3 F150 Regular Cab 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "Stage 3 F150 Super Crew 4WD": {
      2008: "2008",
      2007: "2007",
    },
    "Stage 3 F150 Super Cab 4WD": {
      2008: "2008",
      2007: "2007",
    },
    "Stage 3 F150 Regular Cab 4WD": {
      2008: "2008",
      2007: "2007",
    },
    "Stage 3 F150 Super Crew 2WD": {
      2008: "2008",
      2007: "2007",
    },
    "Boss F150 Super Cab 2WD": {
      2006: "2006",
    },
    "Stage 3 F-150 4WD": {
      2006: "2006",
    },
    "Stage 3 F-150 2WD": {
      2006: "2006",
    },
    "Stage 3 F-150 Supercab 2WD": {
      2006: "2006",
    },
    "Stage 3 F-150 Supercrew 2WD": {
      2006: "2006",
    },
    "Stage 3 F-150 Supercrew 4WD": {
      2006: "2006",
    },
    "Stage 3 F-150 Supercab 4WD": {
      2006: "2006",
    },
    "Stage 3 Mustang Coupe": {
      2007: "2007",
    },
    "F150 Raptor Pickup 4WD": {
      2014: "2014",
    },
  },
  "RUF Automobile": {
    "RUF TURBO 12/CTR3": {
      2018: "2018",
    },
    "RUFTURBO12/CTR3/CTR2017": {
      2019: "2019",
    },
    "TURBO12/CTR3/CTR ANNIVERSARY": {
      2020: "2020",
    },
  },
  Saab: {
    "9-3 Convertible": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-3 Sport Sedan": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "9-5": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-3 SportCombi": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "9-5 Wagon": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-7X AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "9-5 Sedan": {
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "9-5 SportCombi": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "9-3X SportCombi AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "9-3 Viggen": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-5 Sedan AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "9-3": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-3 Viggen Convertible": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "9-3 Sedan AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "9-2x Wagon AWD": {
      2006: "2006",
      2005: "2005",
    },
    "9-3 Aero Sedan AWD": {
      2009: "2009",
      2008: "2008",
    },
    "9-3 Aero SportCombi AWD": {
      2009: "2009",
      2008: "2008",
    },
    "9-4X AWD": {
      2012: "2012",
      2011: "2011",
    },
    "9-4X FWD": {
      2012: "2012",
      2011: "2011",
    },
  },
  "Saleen Performance": {
    "S281 Family": {
      2009: "2009",
      2008: "2008",
    },
    "F150 Supercharged": {
      2008: "2008",
    },
    "S331 Family": {
      2008: "2008",
    },
  },
  Saturn: {
    "Vue FWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Vue AWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    Ion: {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    LW300: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    L300: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    SKY: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Outlook FWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Outlook AWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Aura: {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Vue Hybrid": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Relay FWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Aura Hybrid": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    LW200: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    SL: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    SC: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    SW: {
      2001: "2001",
      2000: "2000",
    },
    "Astra 2DR Hatchback": {
      2009: "2009",
      2008: "2008",
    },
    "Astra 4DR Hatchback": {
      2009: "2009",
      2008: "2008",
    },
    "L100/200": {
      2002: "2002",
      2001: "2001",
    },
    "Relay AWD": {
      2006: "2006",
      2005: "2005",
    },
    LS: {
      2000: "2000",
    },
    LW: {
      2000: "2000",
    },
    L200: {
      2003: "2003",
    },
  },
  Scion: {
    tC: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    xB: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    xD: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    iQ: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "FR-S": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    xA: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "iQ EV": {
      2013: "2013",
    },
    iA: {
      2016: "2016",
    },
    iM: {
      2016: "2016",
    },
  },
  Shelby: {
    "Mustang GT": {
      2008: "2008",
    },
  },
  smart: {
    "fortwo coupe": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "fortwo cabriolet": {
      2017: "2017",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "fortwo electric drive coupe": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
    },
    "fortwo electric drive convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "fortwo convertible": {
      2009: "2009",
      2008: "2008",
    },
    "fortwo electric drive cabriolet": {
      2011: "2011",
    },
    "EQ fortwo (coupe)": {
      2019: "2019",
    },
    "EQ fortwo (convertible)": {
      2019: "2019",
    },
  },
  Spyker: {
    "C8 Spyder": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "C8 Laviolette": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "C8 Spyder Wide Body": {
      2006: "2006",
      2005: "2005",
    },
    "C8 Double 12 Spyder": {
      2007: "2007",
    },
    "C8 Double 12 S": {
      2007: "2007",
    },
    C8: {
      2009: "2009",
    },
    "C8 Aileron": {
      2011: "2011",
      2010: "2010",
    },
  },
  SRT: {
    Viper: {
      2014: "2014",
      2013: "2013",
    },
  },
  STI: {
    S209: {
      2019: "2019",
    },
  },
  Subaru: {
    "Forester AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Legacy AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Impreza AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Outback AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Outback Wagon AWD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    BRZ: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Impreza Wagon/Outback SPT AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Tribeca AWD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Impreza Wagon AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2002: "2002",
      2001: "2001",
    },
    WRX: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Legacy Wagon AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2001: "2001",
      2000: "2000",
    },
    "Crosstrek AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Impreza 4-Door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Impreza Sport 4-Door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Impreza 5-Door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Impreza Sport 5-Door": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Baja AWD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "XV Crosstrek AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "XV Crosstrek Hybrid AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    Ascent: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Ascent Limited/Touring": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Legacy/Outback Wagon AWD": {
      2004: "2004",
      2003: "2003",
    },
    "B9 Tribeca AWD": {
      2007: "2007",
      2006: "2006",
    },
    "Impreza Sport AWD": {
      2016: "2016",
      2015: "2015",
    },
    "Legacy/Outback AWD": {
      2004: "2004",
      2003: "2003",
    },
    "Crosstrek Hybrid AWD": {
      2020: "2020",
      2019: "2019",
    },
    "Impreza Wagon/Outback Sport AWD": {
      2012: "2012",
      2011: "2011",
    },
    "BRZ tS": {
      2020: "2020",
      2018: "2018",
    },
    "Legacy AWD (incl. Outback)": {
      2002: "2002",
    },
    "Legacy Wagon AWD (incl. Outback)": {
      2002: "2002",
    },
    "Impreza Wagon/Outback Sport": {
      2010: "2010",
    },
    "WRX STI Type RA": {
      2018: "2018",
    },
  },
  Suzuki: {
    "Grand Vitara": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Grand Vitara 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2007: "2007",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    SX4: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "SX4 AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Grand Vitara XL7 4WD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Grand Vitara XL7": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Aerio: {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "SX4 Sedan": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    Forenza: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Aerio SX": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "Equator 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Forenza Wagon": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Kizashi AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Swift: {
      2005: "2005",
      2004: "2004",
      2001: "2001",
      2000: "2000",
    },
    "Swift x": {
      2011: "2011",
      2010: "2010",
      2006: "2006",
      2005: "2005",
    },
    "Kizashi S AWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    Reno: {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "Equator 2WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Aerio AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Kizashi S": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "SX4 Sport": {
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
    },
    Kizashi: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Vitara 2Door 4WD": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XL7 FWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Aerio SX AWD": {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Esteem Wagon": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Vitara 4Door 4WD": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XL7 AWD": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    Esteem: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Verona: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Vitara 2Door": {
      2002: "2002",
      2001: "2001",
    },
    "Vitara 4 Door 4WD": {
      2004: "2004",
      2003: "2003",
    },
    "Vitara 4 Door": {
      2004: "2004",
      2003: "2003",
    },
    "Vitara 4Door": {
      2002: "2002",
      2001: "2001",
    },
    "Grand Vitara AWD": {
      2008: "2008",
      2006: "2006",
    },
    "Vitara 2 Door 4WD": {
      2003: "2003",
    },
    "Vitara 2 Door": {
      2003: "2003",
    },
    "Aerio 4WD": {
      2003: "2003",
    },
    "Grand Vitara 4Door 4WD": {
      2000: "2000",
    },
    "Vitara 4Door 2WD": {
      2000: "2000",
    },
    "Vitara 2Door 2WD": {
      2000: "2000",
    },
    "Aerio SX 4WD": {
      2003: "2003",
    },
    "Grand Vitara 4Door 2WD": {
      2000: "2000",
    },
    "SX4 Sport/Anniversary Edition": {
      2011: "2011",
    },
  },
  "Tecstar, LP": {
    "Foose F150 Regular Cab 2WD": {
      2008: "2008",
    },
    "Foose F150 Super Cab 2WD": {
      2008: "2008",
    },
    "Foose F150 Super Crew 2WD": {
      2008: "2008",
    },
    "Foose F150 Regular Cab 4WD": {
      2008: "2008",
    },
    "Foose F150 Super Cab 4WD": {
      2008: "2008",
    },
    "Foose F150 Super Crew 4WD": {
      2008: "2008",
    },
  },
  Tesla: {
    "Model S (60 kW-hr battery pack)": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Model S (85 kW-hr battery pack)": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Model 3 Long Range": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Model S AWD - P90D": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Model S AWD - 90D": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Model 3 Mid Range": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Model 3 Long Range AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Model X Performance (22in Wheels)": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Model S (75 kW-hr battery pack)": {
      2017: "2017",
      2016: "2016",
    },
    "Model S P100D": {
      2019: "2019",
      2018: "2018",
    },
    "Model X AWD - P100D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S (90 kW-hr battery pack)": {
      2016: "2016",
      2015: "2015",
    },
    "Model X AWD - 75D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S AWD - 70D": {
      2016: "2016",
      2015: "2015",
    },
    "Model X Long Range": {
      2020: "2020",
      2019: "2019",
    },
    "Model 3 Standard Range Plus": {
      2020: "2020",
      2019: "2019",
    },
    "Model X AWD - P90D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S Long Range": {
      2020: "2020",
      2019: "2019",
    },
    "Model S Performance (21in Wheels)": {
      2020: "2020",
      2019: "2019",
    },
    "Model Y Performance AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Model X 100D": {
      2019: "2019",
      2018: "2018",
    },
    "Model S AWD - 85D": {
      2016: "2016",
      2015: "2015",
    },
    "Model X AWD - 60D": {
      2017: "2017",
      2016: "2016",
    },
    "Model X AWD - 90D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S AWD - P100D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S AWD - 75D": {
      2017: "2017",
      2016: "2016",
    },
    "Model S 100D": {
      2019: "2019",
      2018: "2018",
    },
    "Model X P100D": {
      2019: "2019",
      2018: "2018",
    },
    "Model S Standard Range": {
      2020: "2020",
      2019: "2019",
    },
    "Model X 75D": {
      2019: "2019",
      2018: "2018",
    },
    "Model S AWD - P85D": {
      2016: "2016",
      2015: "2015",
    },
    "Model S Performance (19in Wheels)": {
      2020: "2020",
      2019: "2019",
    },
    "Model S 75D": {
      2019: "2019",
      2018: "2018",
    },
    "Model S AWD - 60D": {
      2017: "2017",
      2016: "2016",
    },
    "Model X Performance (20in Wheels)": {
      2021: "2021",
      2020: "2020",
    },
    "Model Y Long Range AWD": {
      2021: "2021",
      2020: "2020",
    },
    "Model 3 Standard Range": {
      2020: "2020",
      2019: "2019",
    },
    "Model Y Performance AWD (21in Wheels)": {
      2020: "2020",
    },
    "Model 3 Long Range Performance AWD (19in)": {
      2020: "2020",
    },
    "Model 3 Long Range  AWD": {
      2018: "2018",
    },
    "Model 3 Performance AWD": {
      2021: "2021",
    },
    "Model S (40 kW-hr battery pack)": {
      2013: "2013",
    },
    "Model 3 Long Range Performance AWD (18in)": {
      2020: "2020",
    },
    "Model S (70 kW-hr battery pack)": {
      2016: "2016",
    },
    "Model S AWD (85 kW-hr battery pack)": {
      2014: "2014",
    },
    "Model 3 Long Range Performance AWD (20in)": {
      2020: "2020",
    },
    "Model X Standard Range": {
      2020: "2020",
    },
    "Model X AWD - 100D": {
      2017: "2017",
    },
    "Model 3 Long Range  AWD Performance": {
      2018: "2018",
    },
    "Model S": {
      2012: "2012",
    },
    "Model S AWD - 100D": {
      2017: "2017",
    },
    "Model 3 Long Range AWD Performance": {
      2019: "2019",
    },
    "Model S 75kWh": {
      2018: "2018",
    },
    "Model S Long Range Plus": {
      2020: "2020",
    },
    "Model X Long Range Plus": {
      2021: "2021",
      2020: "2020",
    },
  },
  Toyota: {
    Corolla: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Camry: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tacoma 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tacoma 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tundra 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "4Runner 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Tundra 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "4Runner 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Avalon: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sienna 2WD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Prius: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Land Cruiser Wagon 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sequoia 2WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Sequoia 4WD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "Highlander 2WD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Yaris: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "RAV4 2WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Highlander 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "RAV4 4WD": {
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Matrix: {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Tundra 4WD FFV": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Sienna AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Camry Hybrid LE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Camry Solara": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Prius c": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Camry Solara Convertible": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Sequoia 4WD FFV": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "RAV4 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Avalon Hybrid": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "FJ Cruiser 2WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "FJ Cruiser 4WD": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "Highlander Hybrid 4WD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Highlander AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    RAV4: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Highlander Hybrid AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Camry Hybrid XLE/SE": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "RAV4 EV": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Venza: {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Sienna 4WD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "Highlander Hybrid AWD LE Plus": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Venza AWD": {
      2021: "2021",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    MR2: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Celica: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Prius Eco": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Corolla LE Eco": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
    },
    "Prius v": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    Echo: {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "RAV4 Hybrid AWD": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Highlander: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Matrix AWD": {
      2009: "2009",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "Prius Prime": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Camry Hybrid": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    "C-HR": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Camry XSE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Camry LE/SE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "RAV4 LE/XLE": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    86: {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Prius Plug-in Hybrid": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Camry XLE/XSE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Avalon Hybrid XLE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "RAV4 Limited AWD/SE AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Avalon XLE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Highlander LE/XLE/SE/LTD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Prius AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "RAV4 Limited AWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Corolla Hatchback XSE": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Tacoma 4WD Double Cab Off-Road": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Highlander AWD LE": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Corolla Hatchback": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Camry CNG": {
      2001: "2001",
      2000: "2000",
    },
    "Camry AWD LE/SE": {
      2021: "2021",
      2020: "2020",
    },
    "GR Supra": {
      2021: "2021",
      2020: "2020",
    },
    "Corolla iM": {
      2018: "2018",
      2017: "2017",
    },
    "Venza 4WD": {
      2015: "2015",
      2014: "2014",
    },
    "Tundra 2WD FFV": {
      2017: "2017",
      2016: "2016",
    },
    "Highlander Hybrid AWD LTD/PLAT": {
      2021: "2021",
      2020: "2020",
    },
    "Camry Hybrid XLE": {
      2013: "2013",
      2012: "2012",
    },
    "Corolla XSE": {
      2021: "2021",
      2020: "2020",
    },
    "Highlander Hybrid": {
      2021: "2021",
      2020: "2020",
    },
    "RAV4 AWD LE": {
      2021: "2021",
      2020: "2020",
    },
    "RAV4 AWD TRD OFFROAD": {
      2021: "2021",
      2020: "2020",
    },
    "Corolla Hybrid": {
      2021: "2021",
      2020: "2020",
    },
    "Tacoma 4WD D-CAB MT TRD-ORP/PRO": {
      2021: "2021",
      2020: "2020",
    },
    "Yaris iA": {
      2018: "2018",
      2017: "2017",
    },
    "Highlander Hybrid 2WD": {
      2007: "2007",
      2006: "2006",
    },
    "Corolla XLE": {
      2021: "2021",
      2020: "2020",
    },
    "Camry TRD": {
      2021: "2021",
      2020: "2020",
    },
    "RAV4 Soft Top 2WD": {
      2000: "2000",
    },
    "RAV4 Hybrid  AWD": {
      2021: "2021",
    },
    "Tacoma 4WD TRD PRO": {
      2019: "2019",
    },
    "Avalon TRD": {
      2021: "2021",
    },
    "Camry AWD XLE/XSE": {
      2021: "2021",
      2020: "2020",
    },
    "RAV4 Soft Top 4WD": {
      2000: "2000",
    },
    "Tacoma 4WD Double Cab": {
      2016: "2016",
    },
    "Avalon AWD": {
      2021: "2021",
    },
    "Corolla APEX": {
      2021: "2021",
    },
    "RAV4 Prime 4WD": {
      2021: "2021",
    },
    "Camry Hybrid SE/XLE/XSE": {
      2021: "2021",
    },
  },
  Volkswagen: {
    GTI: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Jetta: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Passat: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Golf: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Touareg: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Tiguan: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Tiguan 4motion": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "New Beetle": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Passat Wagon": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Eos: {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
    },
    CC: {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "CC 4motion": {
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "Passat 4motion": {
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Passat Wagon 4motion": {
      2008: "2008",
      2007: "2007",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "New Beetle Convertible": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    Beetle: {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
    },
    "Jetta SportWagen": {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "Beetle Convertible": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    "Golf R": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2013: "2013",
      2012: "2012",
    },
    "e-Golf": {
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "Golf SportWagen": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    Routan: {
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "Touareg Hybrid": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "Jetta Wagon": {
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    Rabbit: {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
    },
    "Eurovan Camper": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    Eurovan: {
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Jetta Hybrid": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
    },
    Atlas: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Atlas 4motion": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "Arteon 4motion": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "Golf SportWagen 4motion": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    Phaeton: {
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    Cabrio: {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "Beetle Dune": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "Golf Alltrack": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "Beetle Dune Convertible": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    Arteon: {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    R32: {
      2008: "2008",
      2004: "2004",
    },
    "Atlas Cross Sport 4motion": {
      2021: "2021",
      2020: "2020",
    },
    "Atlas Cross Sport": {
      2021: "2021",
      2020: "2020",
    },
    "Jetta GLI": {
      2021: "2021",
      2019: "2019",
    },
    "Routan FWD": {
      2009: "2009",
    },
    "Tiguan Limited": {
      2018: "2018",
    },
    "Tiguan Limited 4motion": {
      2018: "2018",
    },
  },
  Volvo: {
    "S60 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "XC90 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "XC90 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "S60 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
    },
    "XC70 AWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
    },
    "XC60 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
    },
    "S40 FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "S80 AWD": {
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "S80 FWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "V70 FWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "XC60 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2017: "2017",
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "C70 Convertible": {
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "V70 AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "V60 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "V60 CC AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    "V50 FWD": {
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "C30 FWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
      2009: "2009",
      2008: "2008",
    },
    "V50 AWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "S40 AWD": {
      2010: "2010",
      2009: "2009",
      2008: "2008",
      2007: "2007",
      2006: "2006",
      2005: "2005",
    },
    "XC70 FWD": {
      2016: "2016",
      2015: "2015",
      2014: "2014",
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
    "XC90 AWD PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "S90 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "V60 AWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
      2016: "2016",
      2015: "2015",
    },
    V40: {
      2004: "2004",
      2003: "2003",
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "V90 CC AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "V70 R AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "XC60 AWD PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "S90 AWD PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "V90 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "C70 FWD": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
      2010: "2010",
    },
    "V90 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
      2018: "2018",
    },
    "S60 R AWD": {
      2007: "2007",
      2006: "2006",
      2005: "2005",
      2004: "2004",
    },
    "S90 FWD": {
      2019: "2019",
      2018: "2018",
      2017: "2017",
    },
    "S80/S80 Executive": {
      2003: "2003",
      2002: "2002",
      2001: "2001",
    },
    "S60 Inscription FWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "C70 Coupe": {
      2002: "2002",
      2001: "2001",
      2000: "2000",
    },
    "S60 CC AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "S60 Inscription AWD": {
      2018: "2018",
      2017: "2017",
      2016: "2016",
    },
    "XC40 FWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "XC40 AWD": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "V60 Polestar AWD": {
      2018: "2018",
      2017: "2017",
    },
    "V60 PoleStar AWD": {
      2016: "2016",
      2015: "2015",
    },
    "S60 AWD PHEV": {
      2021: "2021",
      2020: "2020",
      2019: "2019",
    },
    "S60 PoleStar AWD": {
      2016: "2016",
      2015: "2015",
    },
    "V60 AWD PHEV": {
      2021: "2021",
      2020: "2020",
    },
    "S60 Polestar AWD": {
      2018: "2018",
      2017: "2017",
    },
    "V70 XC AWD": {
      2002: "2002",
      2001: "2001",
    },
    "S70 AWD": {
      2000: "2000",
    },
    S70: {
      2000: "2000",
    },
    "S80/S80 Premier": {
      2004: "2004",
    },
    S80: {
      2000: "2000",
    },
    "V60 CC FWD": {
      2015: "2015",
    },
    S60: {
      2001: "2001",
    },
    "New C70 Convertible": {
      2006: "2006",
    },
    "XC40 AWD BEV": {
      2021: "2021",
    },
  },
  VPG: {
    "MV-1 CNG": {
      2012: "2012",
      2011: "2011",
    },
    "MV-1": {
      2013: "2013",
      2012: "2012",
      2011: "2011",
    },
  },
};

function autoMakes() {
  // Get all vehicle makes
  let makes = {
    makes: Object.keys(autoData),
  };
  return makes;
}

export { autoMakes };

export {autoData};
